<template>
    <div class="pipeline-stage" v-if="pipeline">
        <div class="pipeline-stage-header">
            <div class="title">
                Takvim
            </div>
            <div class="info clickable">
                <el-popover placement="bottom-end" width="500" trigger="click">
                    <span slot="reference">
                        <el-tooltip content="Filtre" placement="left">
                            <i class="el-icon-date"></i>
                        </el-tooltip>
                    </span>
                    <div style="display: flex; align-items: center; justify-content: space-between">
                        <div class="fluid">
                            <el-date-picker v-model="form.start_at" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                                            placeholder="Baslangic"/>
                        </div>
                        <div class="fluid">
                            <el-date-picker v-model="form.end_at" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                                            placeholder="Bitis"/>
                        </div>
                        <div class="ui button blue icon"  style="border-top-left-radius: 0; border-bottom-left-radius: 0"
                             @click="index">
                            <i class="icon el-icon-search"></i>
                        </div>
                    </div>
                </el-popover>
            </div>
        </div>
        <div class="pipeline-stage-container">
            <div v-if="pipeline" class="stage-tabs">
                <el-tooltip v-for="status in statuses" :content="status.title"
                            placement="bottom">
                    <div class="tab" :class="{'active' : (selectedCalendarStatus==status.id) }" v-on:click="handleStatusChange(status.id)">
                        <span class="bullet" :style="{background: status.color}"></span>
                        {{status.name}}
                    </div>
                </el-tooltip>
            </div>
            <div v-if="pagination && pagination.total > pagination.per_page">
                <el-pagination background
                               layout="prev, pager, next"
                               pager-count="3"
                               :total="pagination.total"
                               :current-page.sync="pagination.current_page"
                               :page-size="pagination.per_page"
                               @current-change="handlePaginationChange"
                />
            </div>
            <div class="pipeline-stage-cards">
                <div class="pipeline-stage-cards" v-loading="pageLoading" :style="transitionStyle">
                    <div v-for="item in calendar.data" v-if="(item.status==selectedCalendarStatus)" :key="item.id" class="pipeline-stage-card" @click="show(item.id,item.card)">
                        <div class="name">
                            <i :class="item.icon ? item.icon : 'mdi mdi-clock'" :style="{color: item.color}"></i>
                            {{ item.card.name }}
                        </div>
                        <div class="date">
                            <small><strong>{{ item.name }}</strong> {{ item.readable_start_at }}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog title="Etkinlik Detayı" :visible.sync="dialogVisible" width="60%">
            <el-form ref="form" :model="form" :label-position="'top'" v-loading="dialogLoading">

                <el-form-item label="Grup" v-if="createData">
                    <el-select v-model="form.crm_task_group_id" placeholder="Gruplar..." filterable class="basic" clearable>
                        <el-option v-for="item in createData.taskGroups" :key="item.id" :label="item.title" :value="item.id"/>
                    </el-select>
                </el-form-item>

                <el-form-item label="Başlık">
                    <el-input v-model="form.name" placeholder="Başlık"/>
                </el-form-item>

                <el-form-item label="Açıklama">
                    <el-input v-model="form.description" placeholder="Açıklama"/>
                </el-form-item>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Başlangıç Tarihi">
                            <el-date-picker v-model="form.start_at" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                                            placeholder="Başlangıç"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Bitiş Tarihi">
                            <el-date-picker v-model="form.end_at" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                                            placeholder="Bitiş"/>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="Durum">
                    <el-select v-model="form.status" placeholder="Seçiniz...">
                        <el-option v-for="item in statuses" :key="item.id" :label="item.title" :value="item.id"/>
                    </el-select>
                </el-form-item>

                <div class="text-right">
                    <div class="ui button labeled icon green" @click="update">
                        <i class="icon el-icon-check"></i> KAYDET
                    </div>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'Calendar',
        computed: mapState([
            'calendar',
            'refreshEvents'
        ]),
        props: ['createData'],
        watch: {
            refreshEvents(newValue) {
                if (newValue) {
                    this.index();
                }
            }
        },
        data() {
            return {
                pageLoading: true,
                transitionStyle: "height: "+(window.innerHeight-170)+"px; display: block; overflow: scroll",
                dialogLoading: false,
                dialogVisible: false,
                selectedCalendarStatus: 1,
                form: {
                    //
                },
                pipeline: null,
                pagination: null,

                statuses: [
                    {
                        id: 1,
                        title: 'Beklemede',
                        name: 'BEK',
                        color: '#819EB3'
                    },
                    {
                        id: 2,
                        title: 'Tamamlandı',
                        name: 'TAM',
                        color: '#99DC4E'
                    },
                    {
                        id: 3,
                        title: 'İptal',
                        name: 'İPT',
                        color: '#DA3349'
                    },
                    {
                        id: 4,
                        title: 'Cevapsız',
                        name: 'CVZ',
                        color: '#DA3349'
                    },
                    {
                        id: 5,
                        title: 'Meşgul',
                        name: 'MŞG',
                        color: '#DA3349'
                    },
                    {
                        id: 6,
                        title: 'Ulaşılamadı',
                        name: 'ULŞ',
                        color: '#DA3349'
                    },
                    {
                        id: 7,
                        title: 'Tekrar Aranacak - Randevu',
                        name: 'TEK',
                        color: '#DA3349'
                    },
                ],
            }
        },
        methods: {
            index() {
                this.pageLoading = true;
                this.pipeline = {};
                this.$http
                    .get(this.serviceLink('calendar'), {
                        params: {
                            page: this.pagination ? this.pagination.current_page : 1,
                            start_at: this.form.start_at ? this.form.start_at : null,
                            end_at: this.form.end_at ? this.form.end_at : null,
                            status: this.selectedCalendarStatus
                        }
                    })
                    .then(response => {
                        this.pageLoading = false;

                        this.pagination = {
                            current_page: response.body.current_page,
                            per_page: response.body.per_page,
                            total: response.body.total,
                        };
                        this.$store.commit("setCalendar", response.body);
                    })
                    .catch(e => {
                        this.pageLoading = false;
                    });
            },

            handlePaginationChange(page) {
                this.pagination.current_page = page;

                this.index();
            }
            ,
            handleStatusChange(status) {
                this.pagination.current_page = 1;
                this.selectedCalendarStatus = status;

                this.index();
            }
            ,

            visibleCardDetail(card) {
                this.$store.commit('setCardShowDialog', true);
                this.$store.commit('setCard', card);
            },


            show(id,card) {
                this.visibleCardDetail(card);
                this.form = {};

                this.$store.commit('setEventId', id);

                // this.$http
                //     .get(this.serviceLink('calendar/' + id))
                //     .then(response => {
                //         this.dialogLoading = false;
                //         this.form = response.body;
                //     })
                //     .catch(e => {
                //         this.dialogLoading = false;
                //     });
            },

            update() {
                this.dialogLoading = true;

                this
                    .$http
                    .put(this.serviceLink('calendar/' + this.form.id), this.form)
                    .then(response => {
                        this.dialogLoading = false;
                        this.dialogVisible = false;

                        this.index();
                    })
                    .catch((e) => {
                        this.dialogLoading = false;
                    });
            },
        },
        mounted() {
            this.pageLoading = false;

            this.index();
        },
    }
</script>