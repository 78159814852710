<template>
    <div class="ps-container page-page-headers view box grow ps" v-loading="pageLoading">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <h1>E-Mail ve SMS Gönderim Raporu</h1>
            </div>
            <div class="text item">
                <el-table :data="items" style="width: 100%">
                    <el-table-column fixed prop="alias" label="Başlık"/>
                    <el-table-column prop="subject" label="Açıklama"/>
                    <el-table-column prop="today" label="Bugün" width="120"/>
                    <el-table-column prop="monthly" label="Bu Ay" width="120"/>
                    <el-table-column label="İşlemler" width="120">
                        <template slot-scope="scope">
                            <el-button @click.native.prevent="showDetails(scope.$index, items)" type="primary">
                                DETAY
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>

        <el-dialog :title="dialogItem ? dialogItem.alias : ''" :visible.sync="dialogTableVisible">
            <div v-loading="dialogLoading">

                <div style="display: flex;" class="margin-bottom-20">
                   <div class="fluid margin-right-20">
                       <el-input  placeholder="Eposta Adresı" v-model="search" />
                   </div>
                    <div class="ui button icon primary" @click="handleSearch">
                        <i class="el-icon-search"></i>
                    </div>
                </div>

                <el-table :data="dialogItems">
                    <el-table-column property="receiver" label="E-Posta"/>
                    <el-table-column property="created_at" label="Tarih" width="200"/>
                </el-table>

                <div class="margin-top-20" style="text-align: center" v-if="pagination">
                    <el-pagination background layout="prev, pager, next" :total="pagination.total"
                                   :current-page.sync="pagination.current_page" @current-change="handleCurrentChange"
                                   :page-size="pagination.per_page"/>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: 'Interaction',
        data() {
            return {
                dialogTableVisible: false,
                pageLoading: true,
                dialogLoading: true,
                items: [],
                dialogItems: null,
                dialogItem: null,
                pagination: null,
                search : ''
            }
        },


        methods: {
            showDetails(index, rows) {
                let items = this.lodash.clone(rows);
                this.dialogItem = items.splice(index, 1)[0];

                this.loadDialogItems();
            },

            index() {
                this.pageLoading = true;

                this.$http
                    .get(this.serviceLink('report'))
                    .then(response => {
                        this.pageLoading = false;
                        this.items = response.body;
                    })
                    .catch(e => {
                        this.pageLoading = false;
                    });
            },

            loadDialogItems() {

                this.dialogLoading = true;

                this.$http
                    .get('report?alias_id=' + this.dialogItem.alias + '&token=' + localStorage.token + '&page=' + (this.pagination ? this.pagination.current_page : 1) + '&email=' + this.search)
                    .then(response => {
                        this.dialogItems = response.body.data;
                        this.pagination = {
                            current_page: response.body.current_page,
                            per_page: response.body.per_page,
                            total: response.body.total,
                        };

                        this.dialogLoading = false;
                        this.dialogTableVisible = true;
                    })
                    .catch(e => {
                        this.dialogLoading = false;
                    });
            },

            handleCurrentChange(val) {
                this.pagination.current_page = val;

                this.loadDialogItems();
            },

            handleSearch() {
                this.pagination.current_page = 1;

                this.loadDialogItems();
            }
        },

        mounted() {
            this.pageLoading = false;

            this.index();
        },
    }
</script>