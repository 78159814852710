<template>
    <div class="pipeline-stage" v-if="pipeline">
        <div class="pipeline-stage-header">
            <div class="title">
                {{ pipeline.name }}
            </div>
            <div class="info">
                {{ pipeline.count }}
            </div>
        </div>
        <div class="pipeline-stage-container">
            <div v-if="pipeline" class="stage-tabs">
                <el-tooltip content="Tümü"
                            placement="bottom">
                    <div class="tab" :class="{'active' : !form.stage_id }" v-on:click="handleClickStage(null)">
                        <span class="bullet" :style="{background: '#4446A2'}"></span>
                        Tümü
                    </div>
                </el-tooltip>
                <el-tooltip :content="item.name" placement="bottom" v-for="item in pipeline.stages" :key="item.id">
                <div class="tab" :class="{'active' : item.id === form.stage_id}" v-on:click="handleClickStage(item)">
                        <span class="bullet" :style="{background: item.color}"></span>
                        {{ item.short_name }}
                    </div>
                </el-tooltip>
            </div>
            <div v-if="pagination && pagination.total > pagination.per_page">
                <el-pagination background
                               layout="prev, pager, next"
                               pager-count="3"
                               :total="pagination.total"
                               :current-page.sync="pagination.current_page"
                               :page-size="pagination.per_page"
                               @current-change="handlePaginationChange"
                />
            </div>
            <div class="pipeline-stage-cards" v-loading="pageLoading" :style="transitionStyle">
                <div v-for="card in orderedCards" :key="card.id" class="pipeline-stage-card"
                     @click="visibleCardDetail(card)">
                    <div class="name">
                        <i :class="card.icon ? card.icon : 'mdi mdi-circle'" :style="{color: card.color}"></i> {{ card.name }}
                    </div>
                    <div class="date">
                        {{ card.readable_date_format }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'Potential',
        computed: {
            ...mapState([
            'potential',
        ]),
            orderedCards() {
                // return _.orderBy(this.cards, 'created_at').reverse();
                return this.cards;
            }
        }
        ,
        data() {
            return {
                pageLoading: true,
                transitionStyle: "height: "+(window.innerHeight-170)+"px; display: block; overflow: scroll",
                form : {
                    //
                },
                isAllCards: true,
                pipeline : null,
                stage : null,
                cards: [],
                pagination: null,
            }
        },
        methods: {
            index() {
                this.pageLoading = true;

                this.$http
                    .get(this.serviceLink('cards') + '&page=' + (this.pagination ? this.pagination.current_page : 1) + '&stage_id=' + (this.form.stage_id ? this.form.stage_id : 'potential'))
                    .then(response => {
                        this.pageLoading = false;

                        this.pagination = {
                            current_page: response.body.current_page,
                            per_page: response.body.per_page,
                            total: response.body.total,
                        };

                        this.cards = response.body.data;

                        this.$store.commit('initOrderCards', response.body.data);
                    })
                    .catch(e => {
                        this.pageLoading = false;
                    });
            },

            handleClickStage(stage) {
                if (!stage) {
                    this.form.stage_id = null;
                } else {
                    this.form.stage_id = stage.id;
                }

                this.pagination = null;

                this.index();
            },

            visibleCardDetail(card) {
                this.$store.commit('setCardShowDialog', true);
                this.$store.commit('setCard', card);
            },

            handlePaginationChange(page) {
                this.pagination.current_page = page;

                this.index();
            },
        },
        mounted() {
            this.pageLoading = false;

            this.pipeline = this.potential;

            this.handleClickStage(null);

            window.Echo.channel("crm").listen(
                ".Remzihoca\\Events\\Crm\\CrmCardChangeEvent",
                e => {
                    if(e.crm_stage_id == this.form.stage_id || (e.crm_stage_id > 50 && e.crm_stage_id < 60 && this.form.stage_id == 'potential')) {
                        this.cards.unshift(
                            {
                                id: e.card_id,
                                name: e.card_name,
                                readable_date_format: e.readable_date_format
                            }
                        )
                    }
                }
            );
            window.Echo.channel("crm").listen(
                ".Remzihoca\\Events\\Crm\\CrmCardStateChangeEvent",
                e => {
                    if(e.new_stage_id == this.form.stage_id || ((e.new_stage_id >= 50 && e.new_stage_id < 60) && (this.form.stage_id == 'potential' || this.form.stage_id == null))) {
                        this.cards.unshift(
                            {
                                id: e.card_id,
                                name: e.card_name,
                                readable_date_format: e.readable_date_format
                            }
                        )
                    }
                    if(e.old_stage_id == this.form.stage_id) {
                        let i = this.cards.map(item => item.id).indexOf(e.card_id);
                        this.cards.splice(i, 1);
                    }
                }
            );

        }
    }
</script>