<template>
	<router-view />
</template>

<script>
    export default {
        name: 'Container',
        data() {
            return {
                pageLoading: true,
            }
        },
        mounted() {
            this.pageLoading = false;
        },
    }
</script>