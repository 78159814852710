import Vue from 'vue'
import Provider from './Provider.vue'
import lodash from 'lodash';
import VueResource from 'vue-resource'
import VueI18n from 'vue-i18n'
import ElementUI from 'element-ui';
import ElementUILocale from 'element-ui/lib/locale'
import ElementUILang from 'element-ui/lib/locale/lang/tr-TR'
import VueHighlightJS from 'vue-highlightjs'
import VueCookies from 'vue-cookies'

import store from './store'
import router from './routes/'
import {Languages} from './resources/lang';
import './components'

Vue.config.performance = true;
Vue.config.productionTip = false;

ElementUILocale.use(ElementUILang);

Vue.use(VueResource);
Vue.use(VueI18n);
Vue.use(ElementUI, {ElementUILocale});
Vue.use(VueHighlightJS);
Vue.use(VueCookies);

Vue.prototype.lodash = lodash;

Vue.http.options.root = 'https://www.remzihoca.com/crm-api';
Vue.http.options.credentials = false;

Vue.mixin({
    methods: {
        openBlankWindow(link) {
            return window.open(link, '_blank');
        },

        goRoute(link) {
            return this.$router.push(link);
        },

        serviceLink(link) {
            return link + '?token=' + localStorage.token
        },

        storeParse(data) {
            return JSON.parse(JSON.stringify(data));
        },
    }
});

Vue.http.interceptors.push((request, next) => {
    next((response) => {
        if (response.status == 401) {
            router.go('/auth/login');
        }
        if (response.status == 302) {
            router.go('/auth/login');
        }
    });
});


//atilla
import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key        : '1c2c83fe8b7d389a2fe1',
    cluster    : 'eu',
    forceTLS   : true,
});

new Vue({
    store,
    router,
    i18n  : new VueI18n({
        locale               : localStorage.lang ? localStorage.lang : 'tr',
        silentTranslationWarn: true,
        messages             : Languages,
    }),
    render: h => h(Provider),

}).$mount('#app')
