<template>
  <el-autocomplete
    v-model="form.q"
    :placeholder="$t('search_placeholder')"
    :fetch-suggestions="querySearchAsync"
    @select="handleSelect"
  >
    <template slot-scope="{item}" v-if="item.id">
      <div style="display:flex;justify-content: space-between;">
        <div style="display:flex;">
          <i :class="item.icon ? item.icon : 'mdi mdi-circle'" v-bind:style="{ color: item.color }" style="padding-right:5px;"></i>
          <div style="padding-right:5px; font-weight: 900;">{{ item.value }}</div>
          <div v-if="item.crm_user" style="padding-right:5px;">({{ item.crm_user.first_name }} {{ item.crm_user.last_name }} {{ item.crm_user.email?' -'+item.crm_user.email:'' }} {{ item.crm_user.phone?' -'+item.crm_user.phone:'' }})</div>
          <div style="opacity: 0.5;font-size: 11px;padding-left: 20px;">{{ item.readable_date_format }}</div>
        </div>

        <div v-bind:style="{ color: item.color }">{{item.pipeline}} => {{ item.stage.name }}</div>
      </div>
    </template>
  </el-autocomplete>
</template>

<script>
export default {
  name: "Search",
  data() {
    return {
      items: [],
      form: {
        //
      }
    };
  },
  methods: {
    querySearchAsync(queryString, cb) {
      this.$http
        .get("search-card?token=" + localStorage.token + "&q=" + queryString)
        .then(response => {
          let items = response.body;
          // let results = queryString
          //   ? items.filter(this.createFilter(queryString))
          //   : items;

          cb(items);
        })
        .catch(e => {
          this.pageLoading = false;
        });
    },

    createFilter(queryString) {
      return link => {
        return (
          link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },

    handleSelect(item) {
      this.$store.commit("setCardShowDialog", true);
      this.$store.commit("setCard", item);
    }
  },
  mounted() {
    //
  }
};
</script>