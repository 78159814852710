<template>
    <div class="section" v-loading="pageLoading">
        <div class="section-header has-button">
            <div class="title">
                Önerilen Ürünler
            </div>
            <div class="actions">
                <el-popover  v-if="!cardShow.current_card.basket_card_id || hasPermission($store.state.user.email,'crm-card-suggestion')" placement="bottom" width="600" trigger="click" title="Önerilen Ürünler">
                    <div class="ui button icon circular primary" slot="reference">
                        <i class="icon el-icon-plus"></i>
                    </div>
                    <div class="crud-pop" v-loading="pageLoading">
                        <div class="fluid">
                            <el-form ref="form" :model="form" :label-position="'top'">
                                <div style="display: flex">
                                    <div v-for="definition in createData.definitions" :key="definition.id"
                                         v-if="definition.parent_id === 0">
                                        <el-form-item class="margin-right-10 margin-bottom-0">
                                            <el-select v-model="form.definitions[definition.id]"
                                                       :placeholder="definition.title" filterable class="basic">
                                                <el-option v-for="item in definition.children" :key="item.id"
                                                           :label="item.title" :value="item.id"/>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                    <el-tooltip content="Filtrele" placement="bottom">
                                        <el-button type="primary" icon="el-icon-search" @click="handleFilter"/>
                                    </el-tooltip>
                                </div>
                                <div class="margin-top-20 margin-bottom-20">
                                    <el-select v-model="form.items" placeholder="Urunler..." multiple filterable
                                               class="basic">
                                        <el-option :disabled="isSuggested(item.id)" v-for="item in createData.all_products" :key="item.id"
                                                   :label="item.name + ' ' + item.variant" :value="item.id"/>
                                    </el-select>
                                </div>

                                <div class="ui button icon labeled green" @click="store">
                                    <i class="el-icon-search icon"></i> Kaydet
                                </div>
                            </el-form>
                        </div>
                    </div>
                </el-popover>
            </div>
        </div>

        <div v-if="cardShow.current_card.basket_card_id">
            <el-alert
                    title="Satış kartı olduğu için bu karta ürün önerisi yapılamaz."
                    type="error"
                    effect="dark" :closable="false">
            </el-alert>
            <el-button @click="visibleCardDetail({id:cardShow.current_card.basket_card_id})" class="primary">Satış Kartına Git <i class="mdi mdi-arrow-right-thick"></i> </el-button>
        </div>
        <div v-for="item in items"  class="section-content no-padding" :class="{ 'bg-primary-lighter': (item.card_id==$store.state.card.id)}">
            <div class="text-right"><strong><small>{{item.card_name}}</small></strong></div>
            <div class="section-list">
                <div class="section-list-item" v-for="suggestion in item.suggestions" :key="item.id">
                    <div>
                        <i :style="{'color':soldColors[suggestion.is_sold]}" class="mdi" :class="{'mdi-emoticon-happy': suggestion.is_sold, 'mdi-emoticon-sad': (suggestion.is_sold==0) || (suggestion.is_sold==-1) }"></i> {{ suggestion.name }} <span v-if="suggestion.variant">({{suggestion.variant}})</span>
                        <div>
                            <small><span v-if="suggestion.agent">{{ suggestion.agent.first_name }} {{ suggestion.agent.last_name }}</span> / <strong>{{ suggestion.date }}</strong></small>
                        </div>
                    </div>
                    <div class="ui button icon red circular" @click="destroy(suggestion.id)">
                        <i class="el-icon-delete"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'RecommendedProducts',
        props: ['createData'],
        computed: mapState([
            'cardShow',
        ]),
        data() {
            return {
                endPoint: null,
                pageLoading: true,
                items: [],
                form: {
                    definitions: [],
                    items: [],
                },
                soldColors: {
                    '0': '#DA3349',
                    '1': '#99DC4E',
                    '-1': '#fbcf30'
                }
            }
        },
        methods: {
            index() {
                this.pageLoading = true;

                this
                    .$http
                    .get(this.serviceLink(this.endPoint))
                    .then(response => {

                        this.items = response.body.items;

                        this.pageLoading = false;
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                    });
            },

            isSuggested(productId) {
                var response = false;
                this.lodash.forEach(this.items, (item,itemKey)=> {
                    if(itemKey==this.cardShow.current_card.id) {
                        this.lodash.forEach(item.suggestions, (suggestion,sKey)=> {
                            if(suggestion.product_id == productId) {
                                response = true
                            }
                        });
                    }
                });
                return response;
            },

            visibleCardDetail(card) {
                this.$store.commit('setCardShowDialog', true);
                this.$store.commit('setCard', card);
            },

            store() {
                this.pageLoading = true;

                this
                    .$http
                    .post('suggest-products?token=' + localStorage.token, {
                        card_id: this.cardShow.current_card.id,
                        product_ids: this.form.items,
                        definitions: this.form.definitions

                    })
                    .then(response => {

                        //KARTIN YENİDEN YÜKLENMESİ SAĞLANIYOR
                        this.visibleCardDetail({
                            id: this.cardShow.current_card.id
                        });

                        this.pageLoading = false;
                        var newItems = [];
                        var formItems = this.form.items;
                        this.createData.all_products.forEach(function (product) {
                            if (formItems.includes(product.id)) {
                                newItems.push(product);
                            }
                        })
                        this.items.push({card_name:'Yeni Önerilenler', suggestions: newItems})
                        this.form = {
                            definitions: [],
                            items: [],
                        };
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                    });
            },

            destroy(id) {
                this.pageLoading = true;

                this.$confirm('Bu veriyi silmek istediginizden eminmisiniz?', 'Uyari!', {
                    confirmButtonText: 'Sil',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                    .then(() => {
                        this.$http
                            .delete(this.serviceLink(this.endPoint + '/' + id))
                            .then(response => {
                                this.pageLoading = false;

                                this.$message({
                                    type: 'success',
                                    message: 'Isleminiz basarili bir sekilde gerceklestirildi.'
                                });

                                this.index();
                            })
                            .catch(error => {
                                this.pageLoading = false;
                            })
                    })
                    .catch(() => {
                        this.pageLoading = false;
                    });
            },

            handleFilter() {
                this.form.items = [];

                let definitions = [];

                this.lodash.forEach(this.form.definitions, (definition, key) => {
                    if (definition) {
                        definitions.push(definition);
                    }
                });

                this.lodash.forEach(this.createData.all_products, (product, key) => {
                    let productDefinitions = [];

                    this.lodash.forEach(product.definitions, (definition, definitionKey) => {
                        productDefinitions.push(definition.definition_id)
                    });

                    let items = this.lodash.intersection(definitions, productDefinitions);

                    if (this.lodash.size(items)) {
                        if (this.lodash.size(items) >= 3) {
                            this.form.items.push(product.id);
                        }
                    }
                });
            }
        },
        mounted() {
            this.pageLoading = false;
            this.endPoint = 'card/' + this.cardShow.current_card.id + '/suggestions';

            this.index();
        },
    }
</script>