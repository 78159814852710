<template>
    <el-row v-loading="pageLoading">
        <el-col :span="24">
            <div style="padding-right: 5px">
                <el-radio-group  @change="handleChangePipeline" v-model="form.pipeline_id" style="margin-bottom: 20px">
                    <el-radio-button :disabled="![2,3].includes(item.id)" v-for="item in createData.stages" :label="item">{{item.name}}</el-radio-button>
                </el-radio-group>
            </div>
        </el-col>
        <el-col :span="24">
            <div style="padding-right: 5px">
                <el-radio-group :fill="newStage.color" @change="handleClickStage" v-if="form.pipeline" size="small" v-model="newStage" style="margin-bottom: 20px">
                    <el-radio-button :disabled="![2,3].includes(form.pipeline.id)" v-for="item in form.pipeline.states" :label="item.id"><i :class="item.icon?item.icon:'mdi mdi-circle'" :style="{color:item.color}"></i> {{item.name}}</el-radio-button>
                </el-radio-group>
            </div>
        </el-col>
    </el-row>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'Stage',
        props: ['createData'],
        computed: mapState([
            'cardShow'
        ]),
        data() {
            return {
                pageLoading: true,
                newPipeline: null,
                newStage: null,
                form: {
                    pipeline: null,
                    pipeline_id: null,
                    stage_id: null,
                }
            }
        },
        methods: {
            initPipeline() {
                this.form.pipeline = this.lodash.filter(this.createData.stages, (o) => {
                    return o.id === this.form.pipeline_id
                })[0];
                this.newStage = this.form.crm_stage_id;
            },

            handleChangePipeline(pipeline) {
                console.log(pipeline);
                this.form.pipeline = pipeline;
            },


            visibleCardDetail(card) {
                this.$store.commit('setCardShowDialog', true);
                this.$store.commit('setCard', card);
            },
            handleClickStage(stage_id) {

                this.pageLoading = true;

                this
                    .$http
                    .post('change-stage?token=' + localStorage.token, {
						card_id : this.cardShow.current_card.id,
						crm_stage_id : stage_id
					})
                    .then(response => {
                        //KARTIN YENİDEN YÜKLENMESİ SAĞLANIYOR
                        this.visibleCardDetail({
                            id: this.cardShow.current_card.id
                        });

                        this.form.crm_stage_id = stage_id;

                        this.pageLoading = false;

                        //this.$store.commit('setRefreshBoard', true);
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                    });
            }
        },
        mounted() {
            this.pageLoading = false;

            this.form.pipeline_id = this.cardShow.current_card.crm_stage_category_id;
            this.form.crm_stage_id = this.cardShow.current_card.crm_stage_id;
            this.initPipeline();
        },
    }
</script>