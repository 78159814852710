<template>
	<el-footer id="Footer">
		<div class="copyright">
			© 2018 <a href="http://ribrit.com">Ribrit.com</a>.
		</div>
	</el-footer>
</template>

<script>
    export default {
        name: 'Footer',
    }
</script>