<template>
	<el-aside id="Sidebar" :width="isCollapse ? width.icon : width.text">
		<el-menu :collapse="isCollapse" @select="goRoute" :default-active.sync="activeLink">
			<el-menu-item index="/" class="logo">
				<img src="@/resources/images/logo.svg" alt="" class="logo-large">
				<img src="@/resources/images/logo.svg" alt="" class="logo-small">
			</el-menu-item>
			<el-menu-item index="/">
				<i class="icon mdi mdi-apple-safari"></i>
				<span>Dashboards</span>
			</el-menu-item>
			<el-submenu index="/apps" popper-class="sidebar-menu">
				<template slot="title">
					<i class="icon mdi mdi-apps"></i>
					<span>Multi Menu</span>
				</template>
				<el-menu-item-group title="Apps">
					<el-menu-item index="/apps/scrumboard">
						Menu 1
					</el-menu-item>
					<el-menu-item index="/apps/crud">
						Menu 2
					</el-menu-item>
					<el-menu-item index="/apps/calendar">
						Menu 3
					</el-menu-item>
					<el-menu-item index="/apps/e-mail">
						Menu 4
					</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
		</el-menu>
	</el-aside>
</template>

<script>
    export default {
        name: 'Sidebar',
		props : ['isCollapse'],
        data() {
            return {
                activeLink : '/',
				width : {
                    icon : '64px',
					text : '240px'
				}
            };
        },
        methods: {
            goRoute(link) {
                if (link.charAt(0) === '/') {
                    this.$router.push(link);
                }

                this.activeLink = link
			}
        },

		mounted() {
            this.activeLink = this.$route.path
		},

		updated() {
            this.activeLink = this.$route.path
        }
    }
</script>