<template>
	<div class="tags display-none">
		<el-tooltip content="Etiket 1" placement="top">
			<div class="tag" :style="{background : '#409eff'}"></div>
		</el-tooltip>
		<el-tooltip content="Etiket 2" placement="top">
			<div class="tag" :style="{background : '#67c23a'}"></div>
		</el-tooltip>
		<el-tooltip content="Etiket 3" placement="top">
			<div class="tag" :style="{background : '#f56c6c'}"></div>
		</el-tooltip>

		<el-tooltip content="Etiket Ekle" placement="top">
			<el-popover placement="bottom-right" width="400" trigger="click">
				<div class="tag add" slot="reference">
					<i class="el-icon-plus" ></i>
				</div>
				<div class="crud-pop" v-loading="pageLoading">
					<div class="fluid">
						<el-select v-model="values" placeholder="Seciminiz..." multiple>
							<el-option v-for="item in items" :key="item.id" :label="item.title" :value="item.id"/>
						</el-select>
					</div>
					<div class="ui button icon green">
						<i class="icon el-icon-check"></i>
					</div>
				</div>
			</el-popover>
		</el-tooltip>
	</div>
</template>

<script>
    export default {
        name: 'Tags',
		props : ['tags'],
        data() {
            return {
                pageLoading: true,
				values : [],
				items : [
					{
						id : 1,
						title : 'Text 1'
					},
					{
						id : 2,
						title : 'Text 2'
					},
					{
						id : 3,
						title : 'Text 3'
					},
				],
            }
        },
        mounted() {
            this.pageLoading = false;
        },
    }
</script>