import LayoutConfig from '../config/layout'
import Container from '../app/Pages/Container'

import Index from '../app/Pages/Reports/Index'
import Interactions from '../app/Pages/Reports/Interaction'
import Target from '../app/Pages/Reports/Target'
import StageStats from '../app/Pages/Reports/StageStats'
import Product from '../app/Pages/Reports/Product'
import Combination from '../app/Pages/Reports/Combination'
import User from '../app/Pages/Reports/User'

export default {
    path: '/reports',
    component: Container,
    meta: {
        auth: true,
        layout: LayoutConfig.full,
        title: 'Raporlar',
    },
    children: [
        {
            path: '/',
            component: Index,
            name: 'reports.index',
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Raporlar'
            },
        },
        {
            path: 'interactions',
            component: Interactions,
            name: 'reports.interactions.index',
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Etkilesim Mesajlari'
            },
        },
        {
            path: 'targets',
            component: Target,
            name: 'reports.targets.index',
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Hedefler'
            },
        },
        {
            path: 'stage-stats',
            component: StageStats,
            name: 'reports.stage_stats.index',
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Kart İstatistikleri'
            },
        },
        {
            path: 'products',
            component: Product,
            name: 'reports.products.index',
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Urun Raporlari'
            },
        },
        {
            path: 'combinations',
            component: Combination,
            name: 'reports.combination.index',
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Satış Hesaplama'
            },
        },
        {
            path: 'user',
            component: User,
            name: 'reports.user.index',
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Kullanıcı Raporları'
            },
        }
    ]
}