<template>
    <div class="section">
        <div class="section-header">
            <div class="title margin-top-20">
                İndirim Kuponları
            </div>
        </div>
        <div class="section-content no-padding">
            <div class="section-list">
                <div class="section-list-item">
                    <div><small>
                            <div class="display-flex bg-yellow-light">
                                <div class="padding-10 text-black"><strong><i class="mdi mdi-sale"></i> Eski Katılımcı İndirimi</strong></div>
                                <div class="text-right padding-10"><strong class="text-black">{{cardShow.user.loyalty_discount}}</strong>
                                </div>
                            </div>
                    </small>
                    </div>
                </div>
                <div class="section-list-item" v-for="coupon in cardShow.user.coupons">
                    <div><small>
                        <el-tooltip placement="top">
                            <div slot="content">{{coupon.description}}</div>
                            <div class="display-flex">

                                <div class="padding-right-10 padding-top-10"><strong><i class="mdi mdi-tag"></i>
                                    {{coupon.name}}</strong><br>
                                    <div><strong>Son Kullanma Tarihi:</strong> {{coupon.readable_end_time}}</div>
                                </div>
                                <div class="text-right padding-10"><strong class="text-green">{{coupon.readable_discount}}</strong><br><strong>{{coupon.discount_code}}</strong>
                                </div>
                            </div>
                        </el-tooltip>
                    </small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'Note',
        props: [],
        computed: mapState([
            'cardShow'
        ]),
        data() {
            return {
                pageLoading: true,
                form: {}
            }
        },
        mounted() {
            this.pageLoading = false;
        },
    }
</script>