<template>
	<div class="el-card" :class="shadow ? 'is-' + shadow + '-shadow' : 'is-always-shadow'">
		<div class="el-card__header" v-if="$slots.header || header">
			<slot name="header">{{ header }}</slot>
		</div>
		<div class="el-card__tabs" v-if="$slots.tabs || tabs">
			<slot name="tabs">{{ tabs }}</slot>
		</div>
		<div class="el-card__body" :style="bodyStyle">
			<slot></slot>
		</div>
		<div class="el-card__collapse" v-if="$slots.collapse">
			<div :class="{ 'el-card__collapse-body' : true, 'active' : isCollapseActive }">
				<slot name="collapse">
					{{ collapse }}
				</slot>
			</div>
			<div class="el-card__collapse-header" @click="collapseHandle">
				<mdi-icon icon="mdi-code-tags" />
				Code
			</div>
		</div>
		<div class="el-card__footer" v-if="$slots.footer || footer">
			<slot name="footer">{{ footer }}</slot>
		</div>
	</div>
</template>

<script>
    export default {
        name: 'PanCard',
        props: {
            header: {},
            tabs: {},
            footer: {},
            collapse: {},
            bodyStyle: {},
            shadow: {
                type: String
            }
        },
        data() {
            return {
                isCollapseActive: false
            }
        },
        methods: {
            collapseHandle() {
                if (this.isCollapseActive) {
                    this.isCollapseActive = false;
                } else {
                    this.isCollapseActive = true;
                }
            }
        }
    };
</script>
