import Vue from 'vue'
import Vuex from 'vuex'
import lodash from 'lodash';

Vue.use(Vuex);
Vue.prototype.lodash = lodash;

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    strict   : debug,
    state    : {
        preloader: true,
        user     : null,
        agents   : null,

        inbox         : null,
        potential     : null,
        order         : null,
        calendar      : null,
        tasks         : null,
        generalTarget : null,
        eventId       : null,
        personalTarget: null,
        notifications : [],

        cardCreateDialog: false,
        cardShowDialog  : false,
        card            : null,
        cardShow        : null,
        refreshBoard    : false,
        refreshEvents   : false,

        allProducts: [],
    },
    mutations: {
        setPreloader(state, payload) {
            state.preloader = payload
        },

        setUser(state, payload) {
            state.user = payload
        },

        setAgents(state, payload) {
            state.agents = payload;
        },

        setInbox(state, payload) {
            state.inbox = payload
        },
        clearSocketClassFromAllCards(state, payload) {
            state.inbox.pipelines[1].stages[0].data.forEach(element => {
                element.is_from_socket = false;
            });
        },
        setPotential(state, payload) {
            state.potential = payload;
        },

        setOrder(state, payload) {
            state.order = payload
        },

        setCalendar(state, payload) {
            state.calendar = payload
        },

        setEventId(state, payload) {
            state.eventId = payload
        },

        setTasks(state, payload) {
            state.tasks = payload
        },

        setGeneralTarget(state, payload) {
            state.generalTarget = payload
        },

        setPersonalTarget(state, payload) {
            state.personalTarget = payload
        },

        setNotifications(state, payload) {
            state.notifications = payload
        },
        addNotifications(state, payload) {
            state.notifications.splice(0, 0, payload);
        },

        setEventData(state, payload) {
            state.calendar.key = Math.random();
            state.calendar.data = payload;
        },

        setCardCreateDialog(state, payload) {
            state.cardCreateDialog = payload
        },

        setCardShowDialog(state, payload) {
            state.cardShowDialog = payload
        },

        setCard(state, payload) {
            state.card = payload
        },

        setCardShow(state, payload) {
            state.cardShow = payload
        },
        resetCardShow(state) {
            state.cardShow = null
        },

        setRefreshBoard(state, payload) {
            state.refreshBoard = payload
        },

        setRefreshEvents(state, payload) {
            state.refreshEvents = payload
        },

        setClearPipeline(state, payload) {

            if (!state.inbox) {
                return;
            }

            state.inbox.key = Math.random();
            state.potential.key = Math.random();
            state.order.key = Math.random();
            state.calendar.key = Math.random();
        },

        initInboxCards(state, payload) {
            //state.inbox = payload
        },

        initPotentialCards(state, payload) {
            //state.inbox = payload
        },

        initOrderCards(state, payload) {
            //state.inbox = payload
        },

        setAllProducts(state, payload) {
            state.allProducts = payload;
        }
    },
    actions  : {
        addPotential(context, data) {
            context.commit('setPotential', data);
        },
        addOrder(context, data) {
            context.commit('setOrder', data);
        },
        addInbox(context, data) {
            inbox.push(data);
        }
    },
    getters  : {
        preloader(state) {
            return state.preloader
        },

        user(state) {
            return state.user
        },

        agents(state) {
            return state.agents
        },

        inbox(state) {
            return state.inbox
        },

        potential(state) {
            return state.potential
        },

        order(state) {
            return state.order
        },

        calendar(state) {
            return state.calendar
        },

        eventId(state) {
            return state.eventId
        },

        tasks(state) {
            return state.tasks
        },

        card(state) {
            return state.card
        },

        generalTarget(state) {
            return state.generalTarget
        },

        personalTarget(state) {
            return state.personalTarget
        },

        notifications(state) {
            return state.notifications
        },

        getAllProducts(state) {
            return state.allProducts;
        },

        canUser: (state) => (permission) => {

            if (state.user != null) {

                let userPermissionObject = JSON.parse(state.user.roles[0].permissions);
                
                if (state.user.permissions != null && state.user.permissions !== ""){
                    let userExtraPermissionObject = JSON.parse(state.user.permissions);
                    userPermissionObject = { ...userPermissionObject , ...userExtraPermissionObject}
                }


                if (!userPermissionObject.hasOwnProperty(permission)) return false;

                if (userPermissionObject[permission] !== true) return false;

                return true;

            } else {
                return false
            }
        }
    },
    plugins  : []
})