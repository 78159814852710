<template>
    <el-header id="Header">
        <div class="logo">
            <router-link to="/">
                <img src="@/resources/images/logo.png" alt="logo">
            </router-link>
        </div>
        <div class="items fluid">
            <div class="item add" @click="handleCreateCard" v-if="$route.name === 'dashboard'">
                <el-tooltip content="Kart Olustur" effect="dark" placement="bottom">
                    <i class="el-icon-plus"></i>
                </el-tooltip>
            </div>
            <div class="item display-none">
                <router-link to="/scrumboard/tags">
                    <el-tooltip content="Etiket Yonetimi" effect="dark" placement="bottom">
                        <i class="mdi mdi-tag-multiple"></i>
                    </el-tooltip>
                </router-link>
            </div>
            <div class="item display-none">
                <router-link to="/scrumboard">
                    <el-tooltip content="Pazarlama Yonetimi" effect="dark" placement="bottom">
                        <i class="mdi mdi-chart-histogram"></i>
                    </el-tooltip>
                </router-link>
            </div>
            <div class="items search-content">
                <div class="item">
                    <Search/>
                </div>
            </div>

            <div class="items">
                <Target/>
                <div class="item">
                    <Task/>
                </div>

                <div class="item">
                    <router-link to="/reports" >
                        <el-tooltip content="Raporlar" effect="dark" placement="bottom" v-show="canUser('cms.crm.reports.read')">
                            <el-button type="primary" class="bg-primary" icon="mdi mdi-chart-pie">Raporlar</el-button>
                        </el-tooltip>
                    </router-link>
                </div>
                <div class="item">
                    <Notifications/>
                </div>
                <div class="item account">
                    <Account/>
                </div>
            </div>
        </div>
    </el-header>
</template>

<script>
    import Search from "./Header/Search";
    import Notifications from "./Header/Notifications";
    import Account from "./Header/Account";
    import {mapState, mapGetters} from 'vuex';
    import Task from "../Pages/ScrumBoard/Stages/Task";
    import Target from "./Header/Target";

    export default {
        name      : 'Header',
        components: {Target, Task, Account, Notifications, Search},

        computed: {
            ...mapState(['user']),
            ...mapGetters(['canUser'])
        },

        methods: {
            collapseNavToggle() {
                this.$emit('collapse-nav-toggle')
            },

            handleCreateCard() {
                if (this.$store.state.cardCreateDialog) {
                    this.$store.commit('setCardCreateDialog', false);
                }

                this.$store.commit('setCardCreateDialog', true);
            }
        }
    }
</script>