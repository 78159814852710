<template>
	<div class="item"  v-loading="pageLoading">
		<div class="progress" v-if="global">
			<div class="progress-item flex card-shadow--small widget small-widget">
				<div class="box content">
					<div class="accent-text font-weight-700">Hedefe Katkı</div>
				</div>
				<div class="box grow">
					<el-progress :percentage="staff.percentage"/>
				</div>
			</div>
			<div class="progress-item flex card-shadow--small widget small-widget">
				<div class="box content ">
					<div class="accent-text font-weight-700">Toplam Hedef</div>
					<div class="o-050 info-text mr-10">
						{{ global.end_at_display }} Gün Kaldı
					</div>
				</div>
				<div class="box grow">
					<el-progress :percentage="global.percentage"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        name: 'Target',
        data() {
            return {
                pageLoading: true,
				staff : null,
				global : null
            }
        },

		methods : {
        	index() {
				this.pageLoading = true;

				this.$http
						.get(this.serviceLink('target'))
						.then(response => {
							this.pageLoading = false;

							this.global = response.body.global;
							this.staff = response.body.staff;
						})
						.catch(e => {
							this.pageLoading = false;
						});
			}
		},

        mounted() {
            this.pageLoading = false;
            this.index();
        },
    }
</script>