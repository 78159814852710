<template>
    <div class="ps-container page-page-headers view box grow ps" v-loading="pageLoading">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <h1>Ürün Satış Hesaplama</h1>
            </div>
            <el-form label-position="left" label-width="100px">
                <el-form-item label="Ürünler">
                    <el-select v-model="selectedProducts" value-key="id" placeholder="Urunler..." multiple filterable>
                        <el-option v-for="(item,index) in filteredProducts" :value="item" :key="item.id" :label="item.name">
                            <div class="option">
                                <span>{{item.name}}</span>
                                <span>{{item['variant'] ?item['variant'] : item['type'] }}</span>
                            </div>
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button :disabled="!selectedProducts.length" type="primary" @click="calculateButton">Hesapla
                    </el-button>
                </el-form-item>
            </el-form>

            <!--<el-table :data="resultData" height="100%" style="width: 100%" v-if="resultData.length" show-summary sum-text="Toplam">-->
            <!--<el-table-column label="Resim" prop="image" width="120px">-->
            <!--<template scope="props">-->
            <!--<el-image style="width: 75px; height: 100px" :src="props.row.image" fit="cover"></el-image>-->
            <!--</template>-->


            <!--</el-table-column>-->
            <!--<el-table-column prop="name" label="Ürün">-->
            <!--</el-table-column>-->
            <!--<el-table-column prop="variant" label="Variant">-->
            <!--</el-table-column>-->
            <!--<el-table-column prop="count" label="Satış Sayısı">-->
            <!--</el-table-column>-->
            <!--</el-table>-->
            <!--            <div v-for="item in resultData.products" :key="item.id">-->
            <!--                {{item.id}}-->
            <!--                {{item.name}}-->
            <!--                {{item.image_file}}-->

            <!--                <div v-for="variant in item.variants">-->
            <!--                    {{variant}}-->
            <!--                </div>-->
            <!--            </div>-->

            <el-row :gutter="24" v-if="resultData.products">
                <el-col :span="12" v-for="product in resultData.products">
                    <div class="main-product">
                        <el-image
                                style="width: 300px;height:300px; border-radius: 10px"
                                :src="product.image_file"
                                fit="cover"></el-image>
                        <div class="info">
                            <span class="title">{{product.name}}</span>
                            <el-row class="stats" :gutter="24">
                                <el-col class="stat" :span="12">
                                    Satış Sayısı :
                                    <span class="number">{{product.count}}</span>
                                </el-col>
                                <el-col class="stat" :span="12">
                                    İade Sayısı :
                                    <span class="number">{{product.return_count}}</span>
                                </el-col>
                                <el-col class="stat" :span="12">
                                    Satış Tutarı :
                                    <span class="number">{{product.total_price}}</span>
                                </el-col>
                                <el-col class="stat" :span="12">
                                    İade Tutarı :
                                    <span class="number">{{product.return_total_price}}</span>
                                </el-col>
                            </el-row>
                            <div class="sub_product">Alt Ürünleri</div>
                            <div class="variants" :gutter="24" v-for="variant in product.variants">
                                <div class="title2">{{variant.variant_title}}</div>
                                <el-row class="stats" :gutter="24">
                                    <el-col class="stat" :span="12">
                                        Satış Sayısı :
                                        <span class="number">{{variant.count}}</span>
                                    </el-col>
                                    <el-col class="stat" :span="12">
                                        İade Sayısı :
                                        <span class="number">{{variant.return_count}}</span>
                                    </el-col>
                                    <el-col class="stat" :span="12">
                                        Satış Tutarı :
                                        <span class="number">{{variant.total_price}}</span>
                                    </el-col>
                                    <el-col class="stat" :span="12">
                                        İade Tutarı :
                                        <span class="number">{{variant.return_total_price}}</span>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="24" v-if="resultData.packages">
                <el-col :span="24" v-for="pack in resultData.packages">
                    <div class="main-product">
                        <el-image
                                style="width: 300px;height:400px; border-radius: 10px"
                                :src="pack.image_file"
                                fit="cover"></el-image>
                        <div class="info">
                            <span class="title">{{pack.name}}<span style="float:right;color:white">Çalışma Paketi</span></span>
                            <el-row class="stats" :gutter="24">
                                <el-col class="stat" :span="12">
                                    Satış Sayısı :
                                    <span class="number">{{Object.values(pack.products)[0].count}}</span>
                                </el-col>
<!--                                <el-col class="stat" :span="12">-->
<!--                                    İade Sayısı :-->
<!--                                    <span class="number">{{pack.return_count}}</span>-->
<!--                                </el-col>-->
                                <el-col class="stat" :span="12">
                                    Satış Tutarı :
                                    <span class="number">{{pack.total_price}}</span>
                                </el-col>
<!--                                <el-col class="stat" :span="12">-->
<!--                                    İade Tutarı :-->
<!--                                    <span class="number">{{pack.return_total_price}}</span>-->
<!--                                </el-col>-->
                            </el-row>
                            <span class="sub_product">Paket Ürünleri</span>
                            <div class="variants" :gutter="24" v-for="product in pack.products">
                                <div class="title2">{{product.title}}</div>
                                <el-col class="stats" :gutter="24">
                                    <el-col class="stat" :span="12">
                                        Satış Sayısı :
                                        <span class="number">{{product.count}}</span>
                                    </el-col>
                                    <el-col class="stat" :span="12">
                                        İade Sayısı :
                                        <span class="number">{{product.return_count}}</span>
                                    </el-col>
                                    <el-col class="stat" :span="12">
                                        Satış Tutarı :
                                        <span class="number">{{product.total_price}}</span>
                                    </el-col>
                                    <el-col class="stat" :span="12">
                                        İade Tutarı :
                                        <span class="number">{{product.return_total_price}}</span>
                                    </el-col>
                                </el-col>
                                <el-col style="padding:10px">
                                    <div class="sub_product">Alt Ürünleri</div>
                                    <div class="variants" :gutter="24" v-for="variant in product.variants">
                                        <div class="title2">{{variant.variant_title}}</div>
                                        <el-row class="stats" :gutter="24">
                                            <el-col class="stat" :span="12">
                                                Satış Sayısı :
                                                <span class="number">{{variant.count}}</span>
                                            </el-col>
                                            <el-col class="stat" :span="12">
                                                İade Sayısı :
                                                <span class="number">{{variant.return_count}}</span>
                                            </el-col>
                                            <el-col class="stat" :span="12">
                                                Satış Tutarı :
                                                <span class="number">{{variant.total_price}}</span>
                                            </el-col>
                                            <el-col class="stat" :span="12">
                                                İade Tutarı :
                                                <span class="number">{{variant.return_total_price}}</span>
                                            </el-col>
                                        </el-row>
                                    </div>
                                </el-col>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>


            <el-row v-if="resultData">
                <el-col :span="6" v-for="(o, index) in resultData.items" :key="o.id" :offset="index > 0 ? 2 : 0">
                    <el-card :body-style="{ padding: '0px' }">
                        <el-image :src="o.image_file" fit="cover"></el-image>
                        <div style="padding: 14px;">
                            <span>{{o.name}}</span>
                            <div class="bottom clearfix">
                                <time class="time">Bireysel Satış Sayısı:</time>
                                <el-button type="text" class="button">{{ o.count }}</el-button>
                            </div>
                            <div class="bottom clearfix">
                                <time class="time">Toplam Satış Miktarı:</time>
                                <el-button type="text" class="button">{{ o.total_price }}</el-button>
                            </div>
                            <div class="bottom clearfix">
                                <time class="time">Toplam İade sayısı:</time>
                                <el-button type="text" class="button">{{ o.return_count }}</el-button>
                            </div>
                            <div class="bottom clearfix">
                                <time class="time">Toplam İade Miktarı:</time>
                                <el-button type="text" class="button">{{ o.return_total_price }}</el-button>
                            </div>
                        </div>
                    </el-card>
                </el-col>

            </el-row>
        </el-card>
    </div>
</template>

<script>
    export default {
        name    : 'Combinations',
        data() {
            return {
                resultText      : null,
                resultData      : [],
                allProducts     : [],
                selectedProducts: [],
                pageLoading     : false,
                items           : [],
            }
        },
        methods : {
            getAllProducts() {
                this.pageLoading = true;

                this.$http
                    .get(this.serviceLink('combination/all-products'))
                    .then(response => {
                        this.pageLoading = false;
                        this.allProducts = response.body;
                    })
                    .catch(e => {
                        this.pageLoading = false;
                    });
            },
            calculateButton() {
                console.log(this.selectedProducts);

                this.pageLoading = true;

                this.$http
                    .post('combination/calculate?token=' + localStorage.token, {
                        products: this.selectedProducts
                    })
                    .then(response => {
                        this.pageLoading = false;
                        this.resultData = response.data;
                    })
                    .catch(e => {
                        this.pageLoading = false;
                    });
            }
        },
        mounted() {
            this.getAllProducts();
        },
        computed: {
            filteredProducts() {
                return this.allProducts;
            },
        },
    }
</script>

<style lang="scss">
    .option {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .main-product {

        display: flex;
        flex-direction: row;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
        border-radius:10px;
        margin-bottom:10px;
        padding: 10px;

        .info {
            padding-top: 10px;
            padding-left: 10px;
            display: flex;
            flex-direction: column;
            width:100%;

            .title {
                font-size: 16px;
                font-weight: bold;
                color: white;
                padding: 10px;
                background-color: #607D8B;
                border-radius: 10px;

            }

            .title2 {
                font-size: 14px;
                font-weight: bold;
                padding:10px;

                background-color: #8080802b;
                border-radius: 10px;

            }

            .sub_product {
                font-size: 15px;
                font-weight: bold;
                padding-bottom: 10px;
                margin-bottom: 10px;
                padding-top: 10px;
                color: black;

                border-bottom: 1px solid rgba(160, 160, 160, 0.4);
            }

            .stats {
                padding-top: 10px;

                .stat {

                    padding: 5px;

                    .number {
                        font-weight: bold;
                        font-size: 15px;
                    }
                }
            }

            .variants {
                padding: 5px;
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3) !important;
                border-radius: 10px;
                margin-bottom:10px;
                margin-top:10px;
            }
        }
    }
</style>