<template>
	<div class="section">
		<div class="section-header">
			<div class="title">
				İşlem Geçmişi
			</div>
		</div>
		<div class="section-content no-padding">
			<div class="section-list">
				<div class="section-list-item" v-for="item in cardShow.process_log" :key="item.id">
					<div>
						<small><strong>{{item.text}}</strong><br>
							{{item.readable_date}} - {{item.date}}
						</small>
					</div>
					<div>

					</div>
					<!-- <div>
						{{item.agent_name}}
					</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'Logs',
		props : [],
		computed: mapState([
			'cardShow'
		]),
        data() {
            return {
                pageLoading: true,
            }
        },
        mounted() {
            this.pageLoading = false;
        },
    }
</script>