<template>
	<div class="ui button">
		<slot></slot>
	</div>
</template>

<script>
    export default {
        name: 'UiButton',
        props: {

        }
    };
</script>
