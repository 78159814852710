<template>
    <div class="section">
        <div class="section-header has-button">
            <div class="title">
                Takvim
            </div>
            <div class="actions">
                <el-dropdown trigger="click" @command="handleCommand">
                    <div class="ui button icon primary circular">
                        <i class="icon el-icon-plus"></i>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="task in tasks" :command="{type : 'task', item : task}">
                            {{ task.title }}
                        </el-dropdown-item>
                        <el-dropdown-item :command="{type : 'empty'}">Bos Etkinlik</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <div class="section-content no-padding" v-loading="pageLoading">
            <div class="section-list" v-if="items">
                <div class="section-list-item " :class="{'bg-primary-lighter':(item.id === selectedEventId || item.card.id === cardShow.current_card.id)}"
                     v-for="item in items" :key="item.id">
                    <div class="margin-right-10 pulse" style="width: 20px">
                        <i class="el-icon-message-solid" :style="{color : findStatusColor(item)}"></i>
                    </div>
                    <div class="margin-right-10 fluid">
                        <div><strong>{{ item.name }}</strong></div>
                        <div>{{ item.description }}</div>
                    </div>
                    <div class="text-right" style="width: 250px">
                        <span class="padding-right-20">{{ item.readable_created_at }}</span>
                        <div class="ui button icon primary" @click="show(item.id)">
                            <i class="mdi mdi-pencil"></i>
                        </div>
                        <div class="ui button icon red" @click="destroy(item.id)">
                            <i class="el-icon-delete"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog :title="form.id ? 'Etkinlik Detayi' : 'Yeni Etkinlik Ekle'" :visible.sync="dialogVisible"
                   append-to-body>
            <el-form ref="form" :model="form" :label-position="'top'" v-loading="dialogLoading">

                <el-form-item label="Gorevler">
                    <el-select v-model="form.crm_task_group_id" placeholder="Gorev seciniz..." clearable filterable @change="handleChangeTask">
                        <el-option v-for="item in createData.taskGroups" :key="item.id" :label="item.title"
                                   :value="item.id"/>
                    </el-select>
                </el-form-item>

                <el-form-item label="Başlık">
                    <el-input v-model="form.name" placeholder="Başlık"/>
                </el-form-item>

                <el-form-item label="Aciklama">
                    <el-input v-model="form.description" placeholder="Aciklama"/>
                </el-form-item>

                <el-form-item label="Tarih">
                    <el-date-picker v-model="form.start_at" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                                    placeholder="Tarih"/>
                </el-form-item>

                <el-form-item label="Durum">
                    <el-select v-model="form.status" placeholder="Seciminiz..." clearable>
                        <el-option v-for="item in statuses" :key="item.id" :label="item.title" :value="item.id"/>
                    </el-select>
                </el-form-item>

                <div class="text-right">
                    <div class="ui button labeled icon green" @click="store" v-if="!form.id">
                        <i class="icon el-icon-check"></i> Kaydet
                    </div>
                    <div class="ui button labeled icon green" @click="update" v-if="form.id">
                        <i class="icon el-icon-check"></i> Guncelle
                    </div>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'Events',
        props: ['createData'],
        computed: mapState([
            'cardShow'
        ]),
        data() {
            return {
                pageLoading: true,
                form: {
                    //
                },

                selectedEventId: this.$store.state.eventId,

                dialogVisible: false,
                dialogLoading: false,

                items: null,

                statuses: [
                    {
                        id: 1,
                        title: 'Beklemede',
                        name: 'BEK',
                        color: '#819EB3'
                    },
                    {
                        id: 2,
                        title: 'Tamamlandı',
                        name: 'TAM',
                        color: '#99DC4E'
                    },
                    {
                        id: 3,
                        title: 'İptal',
                        name: 'İPT',
                        color: '#DA3349'
                    },
                    {
                        id: 4,
                        title: 'Cevapsız',
                        name: 'CVZ',
                        color: '#DA3349'
                    },
                    {
                        id: 5,
                        title: 'Meşgul',
                        name: 'MŞG',
                        color: '#DA3349'
                    },
                    {
                        id: 6,
                        title: 'Ulaşılamadı',
                        name: 'ULŞ',
                        color: '#DA3349'
                    },
                    {
                        id: 7,
                        title: 'Tekrar Aranacak - Randevu',
                        name: 'TEK',
                        color: '#DA3349'
                    },
                ],

                tasks: [],
            }
        },

        methods: {
            initTasks() {
                this.lodash.forEach(this.createData.taskGroups, (task, key) => {
                    this.lodash.forEach(task.stages, (stage, stageKey) => {
                        if (stage.crm_stage_id === this.cardShow.current_card.crm_stage_id) {
                            this.tasks.push(task);
                            return;
                        }
                    });
                });
            },

            index() {
                this.pageLoading = true;

                this.$http
                    .get(this.serviceLink('calendar') + '&user_id=' + this.cardShow.current_card.crm_user_id)
                    .then(response => {
                        this.pageLoading = false;
                        this.items = response.body.data;
                    })
                    .catch(e => {
                        this.pageLoading = false;
                    });
            },

            create() {
                this.form = {};
                this.dialogVisible = true;
            },

            store() {
                this.dialogLoading = true;
                this.form.crm_card_id = this.cardShow.current_card.id;

                this
                    .$http
                    .post(this.serviceLink('calendar'), this.form)
                    .then(response => {
                        this.dialogLoading = false;
                        this.dialogVisible = false;

                        this.$store.commit('setRefreshEvents', true);

                        this.index();
                    })
                    .catch((e) => {
                        this.dialogLoading = false;
                    });
            },

            show(id) {
                this.dialogLoading = true;
                this.dialogVisible = true;

                this
                    .$http
                    .get(this.serviceLink('calendar/' + id), this.form)
                    .then(response => {
                        this.dialogLoading = false;

                        this.form = response.body;
                    })
                    .catch((e) => {
                        this.dialogLoading = false;
                    });
            },

            update() {
                this.dialogLoading = true;

                this
                    .$http
                    .put(this.serviceLink('calendar/' + this.form.id), this.form)
                    .then(response => {
                        this.dialogLoading = false;
                        this.dialogVisible = false;

                        this.$store.commit('setRefreshEvents', true);

                        this.index();
                    })
                    .catch((e) => {
                        this.dialogLoading = false;
                    });
            },

            destroy(id) {
                this.pageLoading = true;

                this.$confirm('Bu veriyi silmek istediginizden eminmisiniz?', 'Uyari!', {
                    confirmButtonText: 'Sil',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                    .then(() => {
                        this.$http
                            .delete(this.serviceLink('calendar/' + id))
                            .then(response => {
                                this.pageLoading = false;

                                this.$message({
                                    type: 'success',
                                    message: 'Isleminiz basarili bir sekilde gerceklestirildi.'
                                });

                                this.$store.commit('setRefreshEvents', true);

                                this.index();
                            })
                            .catch(error => {
                                this.pageLoading = false;
                            })
                    })
                    .catch(() => {
                        this.pageLoading = false;
                    });
            },

            findStatusColor(item) {

                if (item.status === 1) {
                    return '#FBBD08'
                }

                if (item.status === 2) {
                    return '#21BA45'
                }

                return '#DB2828'

            },

            handleCommand(command) {

                if (!command) {
                    return;
                }

                if (command.type === 'empty') {
                    return this.create();
                }

                this.form = {
                    name: command.item.title,
                    description: command.item.description,
                    crm_task_group_id: command.item.id,
                };

                this.dialogVisible = true;
            },

            handleChangeTask() {
                this.lodash.forEach(this.createData.taskGroups, (task, key) => {
                    if (task.id === this.form.crm_task_group_id) {
                        this.form.name = task.title;
                        this.form.description = task.description;
                    }
                })
            }
        },

        mounted() {
            this.pageLoading = false;

            this.initTasks();
            this.index();
        },
    }
</script>