<template>
    <div>
        <div class="margin-top-20 fluid text-left" v-loading="pageLoading">
            <table class="ui table fluid celled">
                <thead>
                <tr>
                    <th>Kart Bilgileri</th>
                    <th width="100" class="text-right">
                        <div class="ui button icon primary" @click="create">
                            <i class="el-icon-plus"></i>
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in items" :key="item.id">
                    <td>
                        {{ item.card.name }}
                    </td>
                    <td class="text-right">
                        <div class="ui button icon " @click="visibleCardDetail(item.card)">
                            <i class="el-icon-search"></i>
                        </div>
                        <div class="ui button icon red" @click="destroy(item.id)">
                            <i class="el-icon-delete"></i>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>

            <div class="text-center margin-top-20" v-if="pagination && pagination.total > pagination.per_page">
                <el-pagination
                        background
                        layout="prev, pager, next"
                        :total="pagination.total"
                        :current-page.sync="pagination.current_page"
                        :page-size="pagination.per_page"
                        @current-change="handlePaginationChange"
                />
            </div>
        </div>

        <el-dialog title="Yeni Kart Ekle" :visible.sync="dialogVisible" width="40%" append-to-body>
            <el-form ref="form" :model="form" :label-position="'top'" v-loading="dialogLoading">
                <el-form-item>
                    <el-autocomplete v-model="form.q" placeholder="Kart ara..." :fetch-suggestions="querySearchAsync" @select="handleSelect"
                                     class="fluid"/>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name   : 'Cards',
        props  : ['task', 'agent_id', 'q'],
        data() {
            return {
                pageLoading: false,
                items      : [],
                pagination : null,

                form: {
                    //
                },

                dialogVisible: false,
                dialogLoading: false,
            }
        },
        methods: {
            index() {
                this.pageLoading = true;


                let extraFilter = "";
                let agentId = parseInt(this.agent_id);

                if (agentId != null && agentId > 0) {
                    extraFilter += "&agent_id=" + this.agent_id;
                }
                if (this.q != null && this.q.length > 0) {
                    extraFilter += "&q=" + this.q;
                }

                this.$http
                    .get(this.serviceLink('tasks/cards') + '&page=' + (this.pagination ? this.pagination.current_page : 1) + '&task_id=' + this.task.id + extraFilter)
                    .then(response => {
                        this.pageLoading = false;

                        this.pagination = {
                            current_page: response.body.current_page,
                            per_page    : response.body.per_page,
                            total       : response.body.total,
                        };

                        this.items = response.body.data;
                    })
                    .catch(e => {
                        this.pageLoading = false;
                    });
            },

            create() {
                this.form = {};
                this.dialogVisible = true;
            },

            store() {
                this.dialogLoading = true;

                this
                    .$http
                    .post(this.serviceLink('tasks/cards'), this.form)
                    .then(response => {
                        this.dialogLoading = false;
                        this.dialogVisible = false;

                        this.index();
                    })
                    .catch((e) => {
                        this.dialogLoading = false;
                    });
            },

            destroy(id) {
                this.pageLoading = true;

                this.$confirm('Bu veriyi silmek istediginizden eminmisiniz?', 'Uyari!', {
                    confirmButtonText: 'Sil',
                    cancelButtonText : 'Iptal',
                    type             : 'warning'
                })
                    .then(() => {
                        this.$http
                            .delete(this.serviceLink('tasks/cards/' + id))
                            .then(response => {
                                this.pageLoading = false;

                                this.$message({
                                    type   : 'success',
                                    message: 'Isleminiz basarili bir sekilde gerceklestirildi.'
                                });

                                this.index();
                            })
                            .catch(error => {
                                this.pageLoading = false;
                            })
                    })
                    .catch(() => {
                        this.pageLoading = false;
                    });
            },

            handlePaginationChange(page) {
                this.pageLoading = true;

                let extraFilter = "";
                let agentId = parseInt(this.agent_id);

                if (agentId != null && agentId > 0) {
                    extraFilter += "&agent_id=" + this.agent_id;
                }
                if (this.q != null && this.q.length > 0) {
                    extraFilter += "&q=" + this.q;
                }


                this.$http
                    .get(this.serviceLink('tasks/cards') + '&page=' + (page ? this.pagination.current_page : 1) + '&task_id=' + this.task.id +extraFilter)
                    .then(response => {
                        this.pageLoading = false;

                        this.pagination = {
                            current_page: response.body.current_page,
                            per_page    : response.body.per_page,
                            total       : response.body.total,
                        };

                        this.items = response.body.data;
                    })
                    .catch(e => {
                        this.pageLoading = false;
                    });
            },

            querySearchAsync(queryString, cb) {
                this.$http
                    .get('search-card?token=' + localStorage.token + '&q=' + queryString)
                    .then(response => {

                        let items = response.body;
                        let results = queryString ? items.filter(this.createFilter(queryString)) : items;

                        cb(results);
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                    });
            },

            createFilter(queryString) {
                return (link) => {
                    return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },

            handleSelect(item) {
                this.form.crm_card_id = item.id;
                this.form.crm_task_id = this.task.id;

                this.store();
            },

            visibleCardDetail(card) {
                this.$store.commit("setCardShowDialog", true);
                this.$store.commit("setCard", card);
            }
        },
        mounted() {
            this.pageLoading = false;

            this.index();
        },
    }
</script>