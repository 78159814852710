<style lang="scss" scoped>
    .list-items {
        .list-item {
            margin-bottom: 5px;
            padding-bottom: 5px;
            border-bottom: 1px solid #111;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
                border: none;
            }

            .counter {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #111;
                border-radius: 4px;
                font-weight: bold;

                &.yellow {
                    color: #E6A23C;
                    background: #fdf6ec;
                    border-color: #f5dab1;
                }

                &.green {
                    color: #67C23A;
                    background: #f0f9eb;
                    border-color: #c2e7b0;
                }
            }
        }
    }
</style>

<template>
    <div v-loading="pageLoading" ref="productReport">
        <el-breadcrumb class="bg-primary-lighter" separator="/">
            <el-breadcrumb-item :to="{ path: '/' }"><i class="mdi mdi-home"></i> Dashboard</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/reports' }">Tüm Raporlar</el-breadcrumb-item>
            <el-breadcrumb-item>Urun Raporlari</el-breadcrumb-item>
        </el-breadcrumb>

        <el-card class="box-card margin-top-40">
            <div slot="header" class="clearfix">
                <h2 class="m-0">Urun Raporlari</h2>
            </div>
            <div class="text item">
                <el-row :gutter="24">
                    <el-col :span="10">
                        <el-date-picker v-model="form.start_at" type="date" placeholder="Başlangıç Tarihini Seçiniz"
                                        value-format="yyyy-MM-dd HH:mm:ss"/>
                    </el-col>
                    <el-col :span="10">
                        <el-date-picker v-model="form.end_at" type="date" placeholder="Bitiş Tarihini Seçiniz"
                                        value-format="yyyy-MM-dd HH:mm:ss"/>
                    </el-col>
                    <el-col :span="4">
                        <div class="d-flex">
                            <el-tooltip class="item" content="Filtrele" placement="top">
                                <el-button @click="index" type="primary" class="p-10" plain icon="el-icon-search"/>
                            </el-tooltip>
                            <el-tooltip class="item" content="İndir" placement="top">
                                <el-button @click="downloadExcel" type="primary" class="p-10" plain icon="el-icon-download"/>
                            </el-tooltip>
                            <FilterRecord alias="crm-product-reports" :filterForm="form" :stage="$refs['productReport']"
                                          v-if="$refs['productReport']"/>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </el-card>

        <div v-if="report">

            <el-row :gutter="24">
                <el-col :span="12">
                    <el-card>
                        <div slot="header" class="clearfix">
                            <h3 class="m-0">En Cok Birlikte Alinan Urunler</h3>
                        </div>
                        <div class="text-item" style="height: 240px; overflow-x: scroll">
                            <div class="list-items">
                                <div class="list-item" v-for="(item, key) in report.bestRelation" :key="key">
                                    <div class="names">
                                        <div class="name" v-for="(name, nameKey) in item.names" :key="nameKey">
                                            {{ name }}
                                        </div>
                                    </div>
                                    <div class="counter green">
                                        <div class="number">
                                            {{ item.count }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="12">
                    <el-card>
                        <div slot="header" class="clearfix">
                            <h3 class="m-0">En Az Birlikte Alinan Urunler</h3>
                        </div>
                        <div class="text-item" style="height: 240px; overflow-x: scroll">
                            <div class="list-items">
                                <div class="list-item" v-for="(item, key) in report.lowRelation" :key="key">
                                    <div class="names">
                                        <div class="name" v-for="(name, nameKey) in item.names" :key="nameKey">
                                            {{ name }}
                                        </div>
                                    </div>
                                    <div class="counter yellow">
                                        <div class="number">
                                            {{ item.count }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>

            <el-card v-if="chart.bestSales">
                <div slot="header" class="clearfix">
                    <h3 class="m-0">Satılan Ürünler</h3>
                </div>
                <div class="text-item">
                    <div class="chart-box" style="max-width: 100%">
                        <bulma-chart :type="'line'" :data="chart.bestSales" :options="chartConfig"/>
                    </div>
                </div>
            </el-card>
            <el-card v-if="chart.diff">
                <div slot="header" class="clearfix">
                    <h3 class="m-0">Satılan / Onerilen Ürünlerin Orani</h3>
                </div>
                <div class="text-item">
                    <div class="chart-box" style="max-width: 100%">
                        <bulma-chart :type="'line'" :data="chart.diff" :options="chartConfig"/>
                    </div>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
    import FilterRecord from "../FilterRecord";
    import BulmaChart from 'vue-bulma-chartjs'

    export default {
        name: 'Product',
        components: {
            FilterRecord,
            BulmaChart
        },
        data() {
            return {
                pageLoading: true,
                form: {
                    start_at: null,
                    end_at: null,
                },
                report: null,
                chart: {
                    bestSales: null,
                    diff: null,
                },

                chartConfig: {
                    segmentShowStroke: false,

                },

                colors: [
                    '#1fc8db',
                    '#fce473',
                    '#42afe3',
                    '#ed6c63',
                    '#97cd76'
                ],
            }
        },

        methods: {
            initForm(form) {
                this.form = form;

                this.index();
            },

            index() {
                if (!this.form.start_at) {
                    return this.$notify({
                        type: 'warning',
                        title: 'Uyarı!',
                        message: 'Başlangıç tarihini dolurmanız gerekiyor.'
                    });
                }

                if (!this.form.end_at) {
                    return this.$notify({
                        type: 'warning',
                        title: 'Uyarı!',
                        message: 'Bitiş tarihini dolurmanız gerekiyor'
                    });
                }

                this.pageLoading = true;
                this.report = null;

                this.$http
                    .get(this.serviceLink('report/products'), {params: this.form})
                    .then(response => {
                        this.pageLoading = false;
                        this.report = response.body;

                        this.chart.bestSales = this.seriesData(this.report.bestSales);
                        this.chart.diff = this.seriesData(this.report.diff);
                    })
                    .catch(e => {
                        this.pageLoading = false;
                    });
            },

            downloadExcel() {
                if (!this.form.start_at) {
                    return this.$notify({
                        type: 'warning',
                        title: 'Uyarı!',
                        message: 'Başlangıç tarihini dolurmanız gerekiyor.'
                    });
                }

                if (!this.form.end_at) {
                    return this.$notify({
                        type: 'warning',
                        title: 'Uyarı!',
                        message: 'Bitiş tarihini dolurmanız gerekiyor'
                    });
                }

                this.pageLoading = true;
                this.report = null;


                console.log(this.$http.options.root+'/'+this.serviceLink('report/products/download-excel')+'&start_at='+this.form.start_at+'&end_at='+this.form.end_at);
                window.open(this.$http.options.root+'/'+this.serviceLink('report/products/download-excel')+'&start_at='+this.form.start_at+'&end_at='+this.form.end_at);


                this.$http
                    .get(this.serviceLink('report/products/download-excel'), {params: this.form})
                    .then(response => {
                        this.pageLoading = false;
                        // this.report = response.body;

                        // this.chart.bestSales = this.seriesData(this.report.bestSales);
                        // this.chart.diff = this.seriesData(this.report.diff);
                    })
                    .catch(e => {
                        this.pageLoading = false;
                    });
            },

            seriesData(item) {
                let data = {
                    labels: item.labels
                };

                data.datasets = item.series.map((e, i) => {
                    return {
                        data: item.data[i],
                        label: item.series[i],
                        borderColor: this.colors[i],
                        pointBackgroundColor: this.colors[i],
                        backgroundColor: this.colors[i]
                    }
                });

                console.log(data);

                return data
            },
        },

        mounted() {
            this.pageLoading = false;
        },
    }
</script>
