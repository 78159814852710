<style lang="scss">
    .chart-box {
        position: relative;
        width: 100%;
        max-width: 400px;
    }

    .widget {
        .icon-box {
            font-size: 30px;
        }
    }

    .d-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
</style>

<template>
    <div class="ps-container page-page-headers view box grow ps" v-loading="pageLoading" ref="reportStage">
        <el-breadcrumb class="bg-primary-lighter" separator="/">
            <el-breadcrumb-item :to="{ path: '/' }"><i class="mdi mdi-home"></i> Dashboard</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/reports' }">Tüm Raporlar</el-breadcrumb-item>
            <el-breadcrumb-item>Kart İstatistikleri</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card class="box-card margin-top-40">
            <div slot="header" class="clearfix">
                <h2 class="m-0">Kart İstatistikleri</h2>
            </div>
            <div class="text item">
                <el-row :gutter="24">
                    <el-col :span="5">
                        <el-cascader placeholder="Başlangıç Aşamasını Seçiniz" :options="stages" v-model="form.old_stage_id" filterable change-on-select clearable/>
                    </el-col>
                    <el-col :span="5">
                        <el-cascader placeholder="Bitiş Aşamasını Seçiniz" :options="stages" v-model="form.new_stage_id" filterable change-on-select clearable/>
                    </el-col>
                    <el-col :span="5">
                        <el-date-picker v-model="form.start_at" type="date" placeholder="Başlangıç Tarihini Seçiniz" value-format="yyyy-MM-dd HH:mm:ss"/>
                    </el-col>
                    <el-col :span="5">
                        <el-date-picker v-model="form.end_at" type="date" placeholder="Bitiş Tarihini Seçiniz" value-format="yyyy-MM-dd HH:mm:ss"/>
                    </el-col>
                    <el-col :span="4">
                        <div class="d-flex">
                            <el-button @click="index" type="primary" class="p-10" plain icon="el-icon-search"/>
                            <FilterRecord alias="crm-stage-reports" :filterForm="form" :stage="$refs['reportStage']" v-if="$refs['reportStage']"/>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </el-card>
        <div v-if="report">
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-col :span="12">
                        <el-card>
                            <div class="widget p-20">
                                <div class="text-uppercase d-flex">
                                    <div class="box grow">
                                        <h2 class="m-0">{{ report.count }}</h2>
                                        <p class="m-0">Önerilen Ürün Sayısı</p>
                                    </div>
                                    <div class="icon-box ph-15 text-green">
                                        <i class="mdi mdi-account-heart-outline"></i>
                                    </div>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="12">
                        <el-card>
                            <div class="widget p-20">
                                <div class="text-uppercase d-flex">
                                    <div class="box grow">
                                        <h2 class="m-0">{{ report.goal }}</h2>
                                        <p class="m-0">Öneri Başarı Oranı</p>
                                    </div>
                                    <div class="icon-box ph-15 text-green">
                                        <i class="mdi mdi-hand-okay"></i>
                                    </div>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="12">
                        <el-card>
                            <div class="widget p-20">
                                <div class="text-uppercase d-flex">
                                    <div class="box grow">
                                        <h2 class="m-0">{{ report.amount }}</h2>
                                        <p class="m-0">Toplam Ürün Değeri</p>
                                    </div>
                                    <div class="icon-box ph-15 text-green">
                                        <i class="mdi mdi-finance"></i>
                                    </div>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="12">
                        <el-card>
                            <div class="widget p-20">
                                <div class="text-uppercase d-flex">
                                    <div class="box grow">
                                        <h2 class="m-0">{{ report.goalAmount }}</h2>
                                        <p class="m-0">Toplam Ürün Satış Değeri</p>
                                    </div>
                                    <div class="icon-box ph-15 text-green">
                                        <i class="mdi mdi-currency-try"></i>
                                    </div>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </el-col>
                <el-col :span="12">
                    <el-card v-if="chart.targetStageCardSeries">
                        <div slot="header" class="clearfix">
                            <h3 class="m-0">Kart Sayısı ve Öneri Sayısı</h3>
                        </div>
                        <div class="text-item">
                            <div class="chart-box" style="max-width: 100%">
                                <bulma-chart :type="'line'" :data="chart.targetStageCardSeries" :options="chartConfig"/>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="report.targetStage ? 12 : 12">
                    <el-card>
                        <div slot="header" class="clearfix">
                            <h3 class="m-0">En Çok Önerilen Ürünler</h3>
                        </div>
                        <div class="text-item">
                            <div class="chart-box" style="max-width:100%;" v-if="chart.products">
                                <bulma-chart :type="'doughnut'" :data="chart.products" :options="chartConfig"/>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="report.targetStage ? 12 : 12">
                    <el-card>
                        <div slot="header" class="clearfix">
                            <h3 class="m-0">En Çok Satılan Ürünler</h3>
                        </div>
                        <div class="text-item">
                            <div class="chart-box" style="max-width:100%;" v-if="chart.sales">
                                <bulma-chart :type="'doughnut'" :data="chart.sales" :options="chartConfig"/>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>

            <el-row :gutter="24">
                <el-col :span="8">
                    <el-card v-if="report.targetStage">
                        <div slot="header" class="clearfix">
                            <h3 class="m-0">Sonraki Aşamalar</h3>
                        </div>
                        <div class="text-item">
                            <div class="chart-box" style="max-width:100%;">
                                <bulma-chart :type="'pie'" :data="chart.targetPie" :options="chartConfig"/>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="16">
                    <el-card v-if="chart.targetStageSeries">
                        <div slot="header" class="clearfix">
                            <h3 class="m-0">Günlere Göre Dağılımı</h3>
                        </div>
                        <div class="text-item">
                            <div class="chart-box" style="max-width: 100%">
                                <bulma-chart :type="'line'" :data="chart.targetStageSeries" :options="chartConfig"/>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>

        </div>
    </div>
</template>

<script>
    import BulmaChart from 'vue-bulma-chartjs'
    import FilterRecord from "../FilterRecord";

    export default {
        name: 'StageStats',
        components: {
            FilterRecord,
            BulmaChart
        },

        data() {
            return {
                pageLoading: false,
                form: {
                    //
                },
                stages: [],
                report: null,

                chartConfig: {
                    segmentShowStroke: false,
                    tooltips: {
                        mode: 'label'
                    }
                },

                colors: [
                    '#1fc8db',
                    '#fce473',
                    '#42afe3',
                    '#ed6c63',
                    '#97cd76'
                ],

                chart: {
                    //
                },
            }
        },

        methods: {
            getStages() {
                this.pageLoading = true;

                this.$http
                    .get(this.serviceLink('report/stages') + '&process=stages')
                    .then(response => {
                        this.pageLoading = false;
                        this.stages = response.body.items;
                    })
                    .catch(e => {
                        this.pageLoading = false;
                    });
            },

            index() {
                if (!this.lodash.size(this.form.old_stage_id) && !this.lodash.size(this.form.new_stage_id)) {
                    return this.$notify({
                        type: 'warning',
                        title: 'Uyarı!',
                        message: 'En az bir işlem hattı seçmelisiniz.'
                    });
                }

                if (this.lodash.size(this.form.old_stage_id) && this.lodash.size(this.form.old_stage_id) < 2) {
                    return this.$notify({
                        type: 'warning',
                        title: 'Uyarı!',
                        message: 'Başlangıç aşaması alt kırılım seçmeniz gerekiyor.'
                    });
                }

                if (this.lodash.size(this.form.new_stage_id) && this.lodash.size(this.form.new_stage_id) < 2) {
                    return this.$notify({
                        type: 'warning',
                        title: 'Uyarı!',
                        message: 'Bitiş aşaması alt kırılım seçmeniz gerekiyor.'
                    });
                }

                if (!this.form.start_at) {
                    return this.$notify({
                        type: 'warning',
                        title: 'Uyarı!',
                        message: 'Başlangıç tarihini dolurmanız gerekiyor.'
                    });
                }

                if (!this.form.end_at) {
                    return this.$notify({
                        type: 'warning',
                        title: 'Uyarı!',
                        message: 'Bitiş tarihini dolurmanız gerekiyor'
                    });
                }

                this.pageLoading = true;
                this.report = null;
                this.chart = {};

                this.$http
                    .get(this.serviceLink('report/stages'), {params: this.form})
                    .then(response => {
                        this.pageLoading = false;
                        this.report = response.body;

                        this.chart.sales = this.recommendedPie(this.report.bestSales);
                        this.chart.products = this.recommendedPie(this.report.bestRecommended);

                        this.chart.targetPie = {
                            labels: this.report.targetStage.pie.labels,
                            datasets: [
                                {
                                    data: this.report.targetStage.pie.data,
                                    backgroundColor: this.colors
                                }
                            ]
                        };

                        this.chart.targetStageCardSeries = this.seriesData(this.report.targetStageCardSeries);
                        this.chart.targetStageSeries = this.seriesData(this.report.targetStageSeries);
                    })
                    .catch(e => {
                        this.pageLoading = false;
                    });
            },

            recommendedPie(items) {

                let labels = [];
                let data = [];

                this.lodash.forEach(items, (item, key) => {
                    labels.push(item.name);
                    data.push(item.total);
                });

                return {
                    labels: labels,
                    datasets: [
                        {
                            data: data,
                            backgroundColor: this.colors
                        }
                    ]
                }
            },

            seriesData(item) {
                let data = {
                    labels: item.labels
                };

                data.datasets = item.series.map((e, i) => {
                    return {
                        data: item.data[i],
                        label: item.series[i],
                        borderColor: this.colors[i],
                        pointBackgroundColor: this.colors[i],
                        backgroundColor: this.colors[i]
                    }
                });

                return data
            },

            initForm(form) {
                this.form = form;

                this.index();
            }
        },

        mounted() {
            this.pageLoading = false;

            this.getStages();
        },
    }
</script>