import LayoutConfig from '../config/layout'
import Container from '../app/Pages/Container'

import Login from '../app/Pages/Auth/Login'


export default {
    path: '/auth',
    name: 'auth',
    component: Container,
    meta: {
        auth: true,
        layout: LayoutConfig.full,
        title: 'Auth',
    },
    children: [
        {
            path: 'login',
            component: Login,
            name: 'auth.login.index',
            meta: {
                auth: true,
                layout: LayoutConfig.content,
                title: 'Login'
            },
        }
    ]
}