<template>
    <div class="customer">
        <div class="header">
            <div class="media">
                <img :src="cardShow.user.profile_photo" alt class="ui image"/>
            </div>
            <div class="buttons">
                <el-tooltip content="SMS" placement="bottom">
                    <div
                        class="ui button icon primary circular"
                        @click="smsDialogVisible = true"
                        v-if="cardShow.user.phone"
                    >
                        <i class="mdi mdi-message"></i>
                    </div>
                </el-tooltip>
                <el-tooltip content="E-Posta" placement="bottom">
                    <div
                        class="ui button icon primary circular"
                        @click="mailDialogVisible = true"
                        v-if="cardShow.user.email"
                    >
                        <i class="mdi mdi-email"></i>
                    </div>
                </el-tooltip>
                <el-tooltip content="Ara" placement="bottom">
                    <div v-if="cardShow.user.phone" class="ui button icon primary circular" @click="callUser">
                        <i class="mdi mdi-phone"></i>
                    </div>
                </el-tooltip>
                <!--<el-tooltip content="buton" placement="bottom">-->
                <!--<div class="ui button icon primary circular">-->
                <!--<i class="icon el-icon-chat-line-square"></i>-->
                <!--</div>-->
                <!--</el-tooltip>-->
                <el-popover placement="bottom" width="400" trigger="click">
                    <el-tooltip content="Musteri Degistir" placement="bottom" slot="reference">
                        <div class="ui button icon primary circular">
                            <i class="icon el-icon-refresh"></i>
                        </div>
                    </el-tooltip>
                    <div class="crud-pop">
                        <div class="fluid">
                            <el-autocomplete
                                v-model="searchQ"
                                placeholder="Musteri ara..."
                                :fetch-suggestions="querySearchAsync"
                                @select="handleSelect"
                                class="fluid"
                            />
                        </div>
                    </div>
                </el-popover>
                <el-tooltip content="Musteri Bilgilerini Guncelle" placement="bottom">
                    <div
                        class="ui button icon primary circular"
                        @click="customerUpdateFormDialogVisible = true"
                    >
                        <i class="icon el-icon-edit"></i>
                    </div>
                </el-tooltip>
            </div>
        </div>
        <div class="user">
            <div class="name">{{ cardShow.user.first_name }} {{ cardShow.user.last_name }}</div>
            <div class="email">{{ cardShow.user.email }}</div>
        </div>
        <div class="items">
            <div class="item">
                <div class="title">Telefon</div>
                <div class="description">
                    <div>
                        {{cardShow.countries[cardShow.user.country_id].emoji}}
                        +{{cardShow.countries[cardShow.user.country_id].phone_code}}
                        {{ cardShow.user.phone }}
                        <i class="el-icon-check confirm"></i>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="title">Adres</div>
                <div class="description" v-for="address in cardShow.user.addresses" :key="address.id">
                    <div>{{ address.address }}</div>
                    <div>{{ address.district_text }} - {{ address.neighborhood_text }}</div>
                    <div>{{ address.town_text }} / {{ address.city_text }}</div>
                </div>
            </div>
            <el-row :gutter="24">
                <el-col :span="12">
                    <div class="item">
                        <div class="title">Doğum Tarihi</div>
                        <div class="description">{{ cardShow.user.birthday?cardShow.user.birthday:'Bilgi Yok' }}</div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="item">
                        <div class="title">Öğrenim Durumu</div>
                        <div class="description">{{ cardShow.user.education?cardShow.user.education:'Bilgi Yok' }}</div>
                    </div>
                </el-col>
            </el-row>

            <el-row :gutter="24">
                <el-col :span="12">
                    <div class="item">
                        <div class="title">Çalıştığı Kurum</div>
                        <div class="description">{{ cardShow.user.institution?cardShow.user.institution:'Bilgi Yok' }}</div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="item">
                        <div class="title">Meslek</div>
                        <div class="description">{{ cardShow.user.job?cardShow.user.job:'Bilgi Yok' }}</div>
                    </div>
                </el-col>
            </el-row>

            <div class="item-pack">
                <el-row :gutter="24">
                    <el-col :span="12">
                        <h3>YDS</h3>
                        <div class="item">
                            <div class="title">Daha Önce YDS'ye girdiniz mi?</div>
                            <div class="description">{{ approveText(cardShow.user.mi_have_you_entered_yds_before) }}</div>
                        </div>
                        <div class="item">
                            <div class="title">Kaç Puan Aldınız?</div>
                            <div class="description">{{ cardShow.user.mi_yds_how_many_points_did_you_get }}</div>
                        </div>
                        <div class="item">
                            <div class="title">Kaç Puan Hedefliyorsunuz?</div>
                            <div class="description">{{ cardShow.user.mi_yds_how_many_points_are_you_aiming }}</div>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <h3>YÖKDİL</h3>
                        <div class="item">
                            <div class="title">Daha Önce YÖKDİL'e girdiniz mi?</div>
                            <div class="description">{{ approveText(cardShow.user.mi_have_you_entered_yokdil_before) }}</div>
                        </div>
                        <div class="item">
                            <div class="title">Kaç Puan Aldınız?</div>
                            <div class="description">{{ cardShow.user.mi_yokdil_how_many_points_did_you_get }}</div>
                        </div>
                        <div class="item">
                            <div class="title">Kaç Puan Hedefliyorsunuz?</div>
                            <div class="description">{{ cardShow.user.mi_yokdil_how_many_points_are_you_aiming }}</div>
                        </div>
                    </el-col>
                </el-row>
                <hr>
                <el-row :gutter="24">
                    <el-col :span="24">
                        <div class="item">
                            <div class="title">ALES'e girmeyi düşünüyor musunuz?</div>
                            <div class="description">{{ approveText(cardShow.user.mi_are_you_planning_to_enter_ales) }}</div>
                        </div>
                        <div class="item">
                            <div class="title">Genel İngilizce eğitimleri almak ister misiniz?</div>
                            <div class="description">{{ approveText(cardShow.user.mi_would_you_like_to_receive_general_english_training) }}</div>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <div class="item">
                            <div class="title">Konuşma eğitimleri almak ister misiniz?</div>
                            <div class="description">{{ approveText(cardShow.user.mi_would_you_like_to_receive_speaking_lessons) }}</div>
                        </div>
                        <div class="item">
                            <div class="title">Çocuğunuzun İngilizce eğitim almasını ister misiniz?</div>
                            <div class="description">{{ approveText(cardShow.user.mi_would_you_like_your_child_to_study_english) }}</div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>

        <el-dialog
            title="Musteri Bilgileri"
            :visible.sync="customerUpdateFormDialogVisible"
            append-to-body
        >
            <el-form
                ref="form"
                :model="customerUpdateForm"
                :label-position="'top'"
                v-loading="dialogLoading"
            >
                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Adi">
                            <el-input v-model="customerUpdateForm.first_name" id="user-firstname" placeholder="Adi"/>
                            <i @click="copyInput('user-firstname')" class="mdi mdi-content-copy"></i>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Soyadi">
                            <el-input v-model="customerUpdateForm.last_name" id="user-lastname" placeholder="Soyadi"/>
                            <i @click="copyInput('user-lastname')" class="mdi mdi-content-copy"></i>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item label="Ülke Kodu">
                                    <el-select v-model="customerUpdateForm.country_id" placeholder="Seciminiz..." clearable searchable>
                                        <el-option v-for="item in cardShow.countries" :key="item.id"
                                                   :label="item.emoji+' '+item.name + ' +'+ item.phone_code" :value="item.id">
                                            {{item.name}} {{item.emoji}} +{{item.phone_code}}
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Telefon">
                                    <el-input v-model="customerUpdateForm.phone" id="user-phone" placeholder="Telefon"/>

                                </el-form-item>
                            </el-col>

                            <el-col :span="24">
                                <i @click="copyInput('user-phone')" class="mdi mdi-content-copy"></i>
                            </el-col>

                        </el-row>


                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="E-Posta">
                            <el-input v-model="customerUpdateForm.email" id="user-email" placeholder="E-Posta"/>
                            <i @click="copyInput('user-email')" class="mdi mdi-content-copy"></i>
                        </el-form-item>
                    </el-col>
                </el-row>

                <div class="text-right">
                    <div class="ui button labeled icon green" @click="updateCustomer">
                        <i class="icon el-icon-check"></i> Kaydet
                    </div>
                </div>
            </el-form>
        </el-dialog>

        <el-dialog title="Sms Gonder" :visible.sync="smsDialogVisible" append-to-body>
            <el-form ref="form" :model="smsForm" :label-position="'top'" v-loading="dialogLoading">

                <el-form-item label="Şablon Seçiniz">
                    <el-select
                        v-model="smsForm.template"
                        value-key="name"
                        placeholder="Seçiniz..."
                        @change="handleSMSTemplateSelect"
                    >
                        <el-option
                            v-for="item in filteredTemplates"
                            :key="item.id"
                            :label="item.name"
                            :value="item"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="Mesajiniz">
                    <el-input type="textarea" v-model="smsForm.message" placeholder="Mesajınız"/>
                </el-form-item>

                <div class="text-right">
                    <div class="ui button labeled icon green" @click="sendSms">
                        <i class="icon el-icon-check"></i> Gonder
                    </div>
                </div>
            </el-form>
        </el-dialog>

        <el-dialog title="E-Posta Gonder" :visible.sync="mailDialogVisible" append-to-body>
            <el-form ref="form" :model="mailForm" :label-position="'top'" v-loading="dialogLoading">
                <el-form-item label="Başlık">
                    <el-input type="text" v-model="mailForm.title" placeholder="Mesajiniz"/>
                </el-form-item>
                <el-form-item label="Mesajiniz">
                    <el-input type="textarea" v-model="mailForm.message" placeholder="Mesajiniz"/>
                </el-form-item>
                <div class="text-right">
                    <div class="ui button labeled icon green" @click="sendMail">
                        <i class="icon el-icon-check"></i> Gonder
                    </div>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name    : "Customer",
    props   : ['createData'],
    computed: {
        ...mapState([
            'cardShow',
        ]),
        filteredTemplates() {
            return this.createData.email_sms_templates.filter(template => {
                var has_relation = false;
                var current_id = this.cardShow.current_card.crm_stage_id;
                template.crm_stage_ids.forEach(function (id) {
                    if (id == current_id) {
                        has_relation = true;
                    }
                })
                return has_relation
            })
        }
    },
    data() {
        return {
            trumbowygConfig: {
                semantic: false
            },
            pageLoading    : true,
            dialogLoading  : false,
            search         : null,
            dialogVisible  : false,
            searchQ        : null,
            form           : {
                //
            },

            smsDialogVisible: false,
            smsForm         : {
                //
            },

            mailDialogVisible: false,
            mailForm         : {
                //
            },
            callForm         : {
                //
            },

            customerUpdateFormDialogVisible: false,
            customerUpdateForm             : {}
        };
    },
    methods : {
        approveText(value) {
            if (value === null) {
                return 'Bilgi Yok!'
            }

            if (value === 0) {
                return 'Hayır'
            }

            return 'Evet'
        },
        store() {
            this.pageLoading = true;

            this.$store.commit("setRefreshBoard", false);
        },

        querySearchAsync(queryString, cb) {
            this.$http
                .get("search-user?token=" + localStorage.token + "&q=" + queryString)
                .then(response => {
                    let items = response.body;
                    let results = queryString
                        ? items.filter(this.createFilter(queryString))
                        : items;

                    cb(items);
                })
                .catch(e => {
                    this.pageLoading = false;
                });
        },

        createFilter(queryString) {
            return link => {
                return (
                    link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
                );
            };
        },

        handleSMSTemplateSelect(value) {
            console.log(value)
            this.smsForm.message = this.smsForm.template.content;
        },

        handleSelect(item) {
            this.pageLoading = true;

            this.$http
                .post("assign-user?token=" + localStorage.token, {
                    card_id: this.cardShow.current_card.id,
                    user_id: item.id
                })
                .then(response => {
                    this.pageLoading = false;

                    this.visibleCardDetail({
                        id: this.cardShow.current_card.id
                    });
                })
                .catch(e => {
                    this.pageLoading = false;
                });
        },

        visibleCardDetail(card) {
            this.$store.commit("setCardShowDialog", true);
            this.$store.commit("setCard", card);
        },

        sendSms() {
            this.dialogLoading = true;
            this.smsForm.receiver = this.cardShow.user.phone;

            this.$http
                .post(this.serviceLink("send-interaction"), this.smsForm)
                .then(response => {
                    this.smsDialogVisible = false;
                    this.dialogVisible = false;
                    this.dialogLoading = false;
                })
                .catch(e => {
                    this.dialogLoading = false;
                });
        },
        callUser() {

            this.callForm.phone = '0' + this.cardShow.user.phone;
            this.$http
                .get(this.serviceLink("call-customer"), {params: this.callForm})
                .then(response => {
                    this.$notify({
                        title   : "Arama Aktarıldı",
                        position: "bottom-right",
                        message : "Arama başarıyla ALOTECH'e yönlendirildi.",
                        type    : "success"
                    });
                })
                .catch(e => {
                    this.$notify({
                        title   : "Bir Hata Oluştu",
                        position: "bottom-right",
                        message : "Arama ALOTECH'e yönlendirilirken bir hata oluştu.",
                        type    : "error"
                    });
                });
        },

        sendMail() {
            this.dialogLoading = true;
            this.mailForm.receiver = this.cardShow.user.email;

            this.$http
                .post(this.serviceLink("send-interaction"), this.mailForm)
                .then(response => {
                    this.mailDialogVisible = false;
                    this.dialogVisible = false;
                    this.dialogLoading = false;
                })
                .catch(e => {
                    this.dialogLoading = false;
                });
        },
        copyInput(id) {
            /* Get the text field */
            var copyText = document.getElementById(id);

            /* Select the text field */
            copyText.select();
            copyText.setSelectionRange(0, 99999); /*For mobile devices*/

            /* Copy the text inside the text field */
            document.execCommand("copy");

            /* Alert the copied text */
            console.log(copyText.value)
        },
        updateCustomer() {
            this.dialogLoading = true;
            this.$http
                .put(this.serviceLink("update-user"), this.customerUpdateForm)
                .then(response => {
                    this.dialogLoading = false;
                    console.log(response.body);
                    this.$store.commit("setCardShowDialog", true);
                    this.$store.commit("setCard", this.cardShow.current_card);
                })
                .catch(e => {
                    this.dialogLoading = false;
                    alert(e);
                });
        }
    },
    mounted() {
        this.pageLoading = false;
    },
    created() {
        // set default customer value for customerUpdateFormDialog
        this.customerUpdateForm = Object.assign({}, this.cardShow.user);
    },
};
</script>