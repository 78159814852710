<template>
	<div class="section">
		<div class="section-header">
			<div class="title">
				Mesaj Gecmisi
			</div>
		</div>
		<div class="section-content no-padding">
			<div class="section-list">
				<div class="section-list-item" v-for="item in cardShow.user.interactions" :key="item.id">
					<div>
						<div><b>{{item.alias}}</b></div>
						<div v-if="item.type =='sms'">{{item.subject}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';

    export default {
        name: 'Messages',
		props : [],
		computed: mapState([
			'cardShow'
		]),
        data() {
            return {
                pageLoading: true,
            }
        },
        mounted() {
            this.pageLoading = false;
        },
    }
</script>