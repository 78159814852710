import Vue from 'vue'
import Router from 'vue-router'
import LayoutConfig from '../config/layout'
import store from '../store'

/**
 * Routes group
 */
import AuthRoutes from './auth'
import ScrumBoardRoutes from './scrumboard'
import ReportsRoutes from './reports'

import Dashboard from '../app/Pages/ScrumBoard/Index'
import Notifications from '../app/Pages/Notifications'

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        AuthRoutes,
        ScrumBoardRoutes,
        ReportsRoutes,
        {
            path: '/',
            name: 'dashboard',
            component: Dashboard,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Dashboard',
            }
        },
        {
            path: '/notifications',
            name: 'notifications',
            component: Notifications,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Bildirimlerim',
            }
        },
        {
            path: '*',
            redirect: '/misc/errors/404'
        }
    ]
});

router.afterEach((to) => {
   setTimeout(function() {
       store.commit('setPreloader', false)
   }, 1000);

   if (to.name !== 'auth.login.index' && !localStorage.token) {
       router.push('/auth/login');
   }
});

router.beforeEach((to, from, next) => {
    if (!to.meta) {
        document.title = 'Crm: RemziHoca.com';
    } else {
        document.title = to.meta.title + ' - Crm: RemziHoca.com';
    }

    next();
});

export default router;