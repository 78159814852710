<template>
	<el-main id="Main" :style="height">
		<router-view />
	</el-main>
</template>

<script>
    export default {
        name: 'Main',
        data() {
            return {
                height: 'height:' + (window.innerHeight - 60) + 'px',
            };
        },
    }
</script>