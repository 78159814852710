<template>
  <el-popover placement="bottom" width="380" trigger="click" >
    <div class="el-popover__content">
      <header class="header">
        <el-tooltip
          class="item"
          effect="dark"
          content="Tümünü Gör"
          placement="left"
          v-if="notifications"
        >
          <router-link to="/notifications">
            <el-button type="orange">Bildirimlerim</el-button>
          </router-link>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="Hepsini okundu olarak isaretle"
          placement="left"
          v-if="notifications"
        >
          <el-button icon="mdi mdi-check" type="orange" @click="markAsRead" size="mini" circle />
        </el-tooltip>
      </header>
      <div class="list-items scrool-height-290" v-if="notifications" v-loading="pageLoading">
        <a @click="showCardDialog(n)" class="item" v-for="n in notifications">
          <span class="media">
            <i class="mdi mdi-briefcase-outline"></i>
          </span>
          <div style="display: flex;flex-direction: column;">
            <div>
              <span class="text">
                <b>{{n.title}}</b>
                &nbsp;
              </span>
            </div>
            <div>
              <span class="text-color-secondary">{{n.message}} &nbsp;</span>
            </div>
            <div>
              <span class="text" style="font-size: smaller;">
                <i>{{n.user}}</i>
              </span>
            </div>
          </div>
        </a>
      </div>
    </div>
    <a slot="reference">
      <el-badge
        :value="notifications.length"
        class="el-badge--orange"
        v-bind:class="{pulse:pulseAnimation}"
      >
        <i class="mdi mdi-bell-ring-outline"></i>
      </el-badge>
    </a>
  </el-popover>
</template>

<script>
    import {mapState} from "vuex";

    export default {
  name: "Notifications",
  computed: mapState(["notifications"]),
  data() {
    return {
      pulseAnimation: false,
      animated: false,
      pageLoading: false
    };
  },
  methods: {
    size() {
      if (!this.board) {
        return 0;
      }
      return parseInt(this.lodash.size(this.notifications));
    },
    animate() {
      console.log("animate fun");
    },
    markAsRead() {
      this.pageLoading = true;
      this.$http
        .get(this.serviceLink('notifications-mark-as-read')  ,{})
        .then(response => {
          this.pageLoading = false;
        })
        .catch(e => {
          alert(e);
          this.pageLoading = false;
          this.pulseAnimation = false;
        });
      this.pulseAnimation = false;
    },
    showNotification(notification) {
      this.$notify({
        title: notification.title,
        position: "bottom-right",
        message: notification.message,
        type: "info",
        onClick: () => {
          this.showCardDialog(notification);
        }
      });
    },
    showCardDialog(notification) {
      let data = { id: notification.card_id };
      this.$store.commit("setCardShowDialog", true);
      this.$store.commit("setCard", data);
    }
  },
  mounted() {
    window.Echo.channel("crm").listen(
      ".Remzihoca\\Events\\Crm\\CrmCardChangeEvent",
      e => {
          console.log("CrmCardChangeEvent", e);
        this.$store.commit("addNotifications", e);
        this.showNotification(e);

        this.pulseAnimation = true;
      }
    );
    window.Echo.channel("crm").listen(
      ".Remzihoca\\Events\\Crm\\CrmCardStateChangeEvent",
      e => {
          console.log("CrmCardStateChangeEvent", e);
        this.$store.commit("addNotifications", e);

        this.showNotification(e);
        this.pulseAnimation = true;
      }
    );
    // window.Echo.private("Remzihoca.Models.User.277324").notification(notification => {
    //   console.log(notification);
    // });
  }
};
</script>
<style scoped>
.pulse {
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(242, 113, 27, 1);
  animation: pulse 1.5s infinite;
}
.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
</style>