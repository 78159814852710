<template>
    <div class="ps-container page-page-headers view box grow ps" v-loading="pageLoading">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <h1>Crm Hedefler</h1>
            </div>
            <div class="text item">
                <el-table :data="items" style="width: 100%">
                    <el-table-column fixed prop="title" label="Başlık"/>
                    <el-table-column prop="current_total" label="Aktif Bilanco"/>
                    <el-table-column prop="target_total" label="Hedef bilanco" width="140"/>
                    <el-table-column prop="start_at" label="Baslangic Tarihi" width="160"/>
                    <el-table-column prop="end_at" label="Bitis Tarihi" width="160"/>
                    <el-table-column label="İşlemler" width="120">
                        <template slot-scope="scope">
                            <el-button @click.native.prevent="show(scope.$index, items)" type="primary">
                                DETAY
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>

        <el-dialog title="Hedef Raporu" :visible.sync="dialogVisible">
            <div v-if="dialogItem">
                <div class="ui header huge">
                    {{ dialogItem.title }}
                    <div class="sub header">
                        {{ dialogItem.start_at }} - {{ dialogItem.end_at }}
                    </div>
                </div>

                <el-row :gutter="24" style="margin-top: 40px">
                    <el-col :span="12">
                        <el-card class="box-card">
                            <div>
                                <h2 class="no-margin">{{ dialogItem.current_total }}</h2>
                                <div>Aktif Bilanco</div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="12">
                        <el-card class="box-card">
                            <div>
                                <h2 class="no-margin">{{ dialogItem.target_total }}</h2>
                                <div>Hedef Bilanco</div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>

                <table class="ui table celled">
                    <thead>
                    <tr>
                        <th>Personel</th>
                        <th class="text-right" width="100">Katki</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="staff in dialogItem.staff" :key="staff.id">
                        <td>
                            {{ staff.full_name }}
                        </td>
                        <td class="text-right">
                            {{ staff.target_total ? staff.target_total : 0 }} TL
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: 'Index',
        data() {
            return {
                pageLoading: true,
                dialogVisible : false,
                items : [],
                dialogItem : null,
            }
        },

        methods: {
            index() {
                this.pageLoading = true;

                this.$http
                    .get(this.serviceLink('targets'))
                    .then(response => {
                        this.pageLoading = false;
                        this.items = response.body;
                    })
                    .catch(e => {
                        this.pageLoading = false;
                    });
            },

            show(index, rows) {
                let items = this.lodash.clone(rows);
                this.dialogItem = items.splice(index, 1)[0];

                this.dialogVisible = true;
            }
        },

        mounted() {
            this.pageLoading = false;

            this.index();
        },
    }
</script>