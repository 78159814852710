export default {
    content: {
        header: false,
        sidebar: false,
        main: 'only-content',
        footer: false
    },
    full: {
        header: true,
        sidebar: true,
        main: null,
        footer: false
    }
}