<template>
    <el-popover placement="bottom" width="300" trigger="click" v-if="user">
        <img src="@/resources/images/avatars/ninja.png" alt="" class="avatar" slot="reference"
             v-if="!user.profile_photo">
        <img :src="user.profile_photo" alt="" class="avatar" slot="reference" v-if="user.profile_photo">

        <div class="header-popover-account">
            <header class="header">
                <div class="avatar">
                    <img src="@/resources/images/avatars/ninja.png" alt="avatar" v-show="!user.profile_photo">
                    <img :src="user.profile_photo" alt="avatar" v-show="user.profile_photo">
                </div>
                <div class="text">
                    <div class="name">{{ user.first_name }} {{ user.last_name }}</div>
                    <div class="role">{{ user.job }}</div>
                </div>
            </header>
            <div class="progress">
                <div class="flex p-20 ml-20 card-shadow--small widget small-widget" v-if="personalTarget">
                    <div class="box mr-10">
                        <div class="accent-text font-weight-700">Bireysel Hedef</div>
                    </div>
                    <div class="o-050 info-text mr-10">{{ personalTarget.date }}</div>
                    <div class="box grow">
                        <el-progress :percentage="personalTarget.percentage"/>
                    </div>
                </div>
                <div class=" flex p-20 ml-20 card-shadow--small widget small-widget" v-if="generalTarget">
                    <div class="box mr-10">
                        <div class="accent-text font-weight-700">Toplam Hedef</div>
                    </div>
                    <div class="o-050 info-text mr-10">{{ generalTarget.date }}</div>
                    <div class="box grow">
                        <el-progress :percentage="generalTarget.percentage" status="exception"/>
                    </div>
                </div>
            </div>
            <nav class="nav">
                <a href="" class="nav-item display-none">
                    <i class="el-icon-user icon"></i>
                    Profil
                </a>
                <a href="" class="nav-item display-none">
                    <i class="el-icon-setting icon"></i>
                    Ayarlar
                </a>
                <div class="nav-item cursor-pointer" @click="logout">
                    <i class="mdi mdi-logout icon"></i>
                    Cikis Yap
                </div>
            </nav>
        </div>
    </el-popover>
</template>

<script>
import {mapState} from 'vuex';

const axios = require('axios');

export default {
    name: 'Account',
    computed: mapState([
        'user',
        'generalTarget',
        'personalTarget',
    ]),
    mounted() {
        if (!this.user) {
            this.getUser();
        }
    },
    methods: {
        logout() {
            this.$cookies.remove('platform-token');
            localStorage.removeItem('token');
            this.goRoute('auth/login');
        },

        getUser() {
            axios.get('https://www.remzihoca.com/platform/get-user?token=' + localStorage.token, this.form)
                .then((response) => {
                    this.$store.commit('setUser', response.data.user);
                })
                .catch((error) => {
                    this.loading = false;

                    localStorage.removeItem('token');

                    this.goRoute('auth/login');
                });
        }
    },
}
</script>
