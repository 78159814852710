<template>
    <div class="section">
        <div class="section-header has-button">
            <div class="title">
                Hedef Sınav
            </div>
            <div class="actions">
                <div class="ui button icon primary circular" @click="create">
                    <i class="icon el-icon-plus"></i>
                </div>
            </div>
        </div>
        <div class="section-content no-padding">
            <div class="section-list" v-loading="pageLoading">
                <div class="section-list-item" v-for="item in items" :key="item.id">
                    <div>
                        <div class="fs-18 font-weight-600">{{ item.exam.title }} : {{ item.point }}</div>
                        <div>{{ item.period.title }} : {{ item.year.title }}</div>
                    </div>
                    <div>
                        <div class="ui button icon" @click="show(item.id)">
                            <i class="el-icon-edit"></i>
                        </div>
                        <div class="ui button icon red" @click="destroy(item.id)">
                            <i class="el-icon-delete"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog title="Hedef Sınav" :visible.sync="dialogVisible" append-to-body>
            <el-form ref="form" :model="form" :label-position="'top'" v-loading="dialogLoading">
                <el-form-item label="Sinavlar">
                    <el-select v-model="form.exam_id" placeholder="Seciminiz...">
                        <el-option v-for="item in createData.exams" :key="item.id" :label="item.title"
                                   :value="item.id"/>
                    </el-select>
                </el-form-item>

                <el-form-item label="Donemler">
                    <el-select v-model="form.period_id" placeholder="Seciminiz...">
                        <el-option v-for="item in createData.periods" :key="item.id" :label="item.title"
                                   :value="item.id"/>
                    </el-select>
                </el-form-item>

                <el-form-item label="Yillar">
                    <el-select v-model="form.year_id" placeholder="Seciminiz...">
                        <el-option v-for="item in createData.years" :key="item.id" :label="item.title"
                                   :value="item.id"/>
                    </el-select>
                </el-form-item>

                <el-form-item label="Puan">
                    <el-input v-model="form.point" placeholder="Puan"/>
                </el-form-item>

                <div class="text-right">
                    <div class="ui button labeled icon green" v-if="!form.id" @click="store">
                        <i class="icon el-icon-check"></i> Kaydet
                    </div>

                    <div class="ui button labeled icon green" v-if="form.id" @click="update">
                        <i class="icon el-icon-check"></i> Guncelle
                    </div>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'TargetExam',
        props: ['createData'],
        computed: mapState([
            'cardShow'
        ]),
        data() {
            return {
                pageLoading: true,
                dialogVisible: false,
                dialogLoading: false,
                alias: 'target',
                form: {
                    //
                },
                items: [],
            }
        },

        methods: {
            index() {
                this.pageLoading = true;

                this.$http
                    .get(this.serviceLink('cards/exams') + '&alias=' + this.alias + '&card_id=' + this.cardShow.current_card.id)
                    .then(response => {
                        this.pageLoading = false;
                        this.items = response.body;
                    })
                    .catch(e => {
                        this.pageLoading = false;
                    });
            },

            create() {
                this.form = {};
                this.dialogVisible = true;
            },

            store() {
                this.dialogLoading = true;
                this.form.crm_card_id = this.cardShow.current_card.id;
                this.form.alias = this.alias;

                this
                    .$http
                    .post(this.serviceLink('cards/exams'), this.form)
                    .then(response => {
                        this.dialogLoading = false;
                        this.dialogVisible = false;

                        this.index();
                    })
                    .catch((e) => {
                        this.dialogLoading = false;
                    });
            },

            show(id) {
                this.dialogLoading = true;
                this.dialogVisible = true;

                this
                    .$http
                    .get(this.serviceLink('cards/exams/' + id), this.form)
                    .then(response => {
                        this.dialogLoading = false;

                        this.form = response.body;
                    })
                    .catch((e) => {
                        this.dialogLoading = false;
                    });
            },

            update() {
                this.dialogLoading = true;

                this
                    .$http
                    .put(this.serviceLink('cards/exams/' + this.form.id), this.form)
                    .then(response => {
                        this.dialogLoading = false;
                        this.dialogVisible = false;

                        this.index();
                    })
                    .catch((e) => {
                        this.dialogLoading = false;
                    });
            },

            destroy(id) {
                this.pageLoading = true;

                this.$confirm('Bu veriyi silmek istediginizden eminmisiniz?', 'Uyari!', {
                    confirmButtonText: 'Sil',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                    .then(() => {
                        this.$http
                            .delete(this.serviceLink('cards/exams/' + id))
                            .then(response => {
                                this.pageLoading = false;

                                this.$message({
                                    type: 'success',
                                    message: 'Isleminiz basarili bir sekilde gerceklestirildi.'
                                });

                                this.index();
                            })
                            .catch(error => {
                                this.pageLoading = false;
                            })
                    })
                    .catch(() => {
                        this.pageLoading = false;
                    });
            },
        },

        mounted() {
            this.pageLoading = false;

            this.index();
        },
    }
</script>