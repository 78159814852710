<template>
  <div class="ps-container page-page-headers view box grow ps" v-loading="pageLoading">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <h1>Tüm Bildirimlerim</h1>
      </div>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input v-model="search" placeholder="Arama"></el-input>
        </el-col>

        <el-button
          style="float:right;"
          icon="el-icon-search"
          type="primary"
          @click="handleCurrentChange(items.current_page,true)"
        >Tümünde Ara</el-button>
      </el-row>

      <div class="text item">
        <el-table
          :data="items.data.filter(interaction => !this.search || interaction.data.title.toLowerCase().includes(this.search.toLowerCase()))"
          style="width: 100%"
        >
          <el-table-column prop="data.title" label="Başlık" />
          <el-table-column prop="data.message" label="Açıklama" />
          <el-table-column prop="data.user" label="Kullanıcı" />
          <el-table-column prop="read_at" label="Okundu" />
          <el-table-column prop="created_at" label="Tarih" />
          
          <el-table-column width="300"></el-table-column>
        </el-table>
        <div class="margin-top-20" style="text-align: center">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="items.total"
            :current-page.sync="items.current_page"
            @current-change="handleCurrentChange"
            :page-size="items.per_page"
          />
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "Report",
  data() {
    return {
      dialogTableVisible: false,
      pageLoading: true,
      dialogLoading: true,
      items: {
        data: []
      },
      searchForm: {},
      dialogItems: null,
      dialogItem: null,
      pagination: null,
      search: ""
    };
  },

  methods: {
    index() {
      this.pageLoading = true;

      this.$http
        .get(this.serviceLink("notifications"))
        .then(response => {
          this.pageLoading = false;
          this.items = response.body;
        })
        .catch(e => {
          this.pageLoading = false;
        });
    },
    searchAll() {
      this.dialogLoading = true;
      this.$http
        .get(this.serviceLink("notifications"))
        .then(response => {
          this.pageLoading = false;
          this.items = response.body;
        })
        .catch(e => {
          this.pageLoading = false;
        });
    },
    handleCurrentChange(val, isAllSearch = false) {
      console.log(isAllSearch);
      this.pageLoading = true;
      this.$http
        .get(
          "notifications?page=" +
            val +
            (isAllSearch == true ? "&all_search=" + this.search : "") +
            "&token=" +
            localStorage.token
        )
        .then(response => {
          this.pageLoading = false;
          this.items = response.body;
        })
        .catch(e => {
          this.pageLoading = false;
        });
    }
  },

  mounted() {
    this.pageLoading = false;

    this.index();
  }
};
</script>