import LayoutConfig from '../config/layout'
import Container from '../app/Pages/Container'

import Index from '../app/Pages/ScrumBoard/Index'

export default {
    path: '/scrumboard',
    component: Container,
    meta: {
        auth: true,
        layout: LayoutConfig.full,
        title: 'ScrumBoard',
    },
    children: [
        {
            path: '/',
            component: Index,
            name: 'scrumboard.index',
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Pazarlama Yonetimi'
            },
        },
        {
            path: 'tags',
            component: Index,
            name: 'scrumboard.tags.index',
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Etiket Yonetimi'
            },
        }
    ]
}