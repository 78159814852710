<template>
	<div class="section">
		<div class="section-header">
			<div class="title">
				Notlar
			</div>
		</div>
		<div class="section-content no-padding">
			<div class="section-list">
				<div class="section-list-item" v-if="card.description" v-for="card in cardShow.other_cards">
					<div>
						<div>
							{{card.description}}
						</div>
						<div class="fs-12 margin-top-5">
							{{card.readable_date_format}}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'Note',
		props : [],
        computed: mapState([
            'cardShow'
        ]),
        data() {
            return {
                pageLoading: true,
				form : {

				}
            }
        },
        mounted() {
            this.pageLoading = false;
        },
    }
</script>