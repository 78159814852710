<template>
    <div class="section">
        <div class="section-header has-button">
            <div class="title">
                Gorevler
            </div>
            <div class="actions">
                <div class="ui button icon primary circular" @click="create">
                    <i class="icon el-icon-plus"></i>
                </div>
            </div>
        </div>
        <div class="section-content no-padding" v-loading="pageLoading">
            <div class="section-list" v-if="items">
                <div class="section-list-item" v-for="item in items" :key="item.id">
                    <div class="margin-right-10">
                        {{ item.task.name }}
                    </div>
                    <div class="ui button icon red" @click="destroy(item.id)">
                        <i class="el-icon-delete"></i>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog title="Gorevler" :visible.sync="dialogVisible" append-to-body>
            <el-form ref="form" :model="form" :label-position="'top'" v-loading="dialogLoading">
                <el-form-item>
                    <el-select v-model="form.crm_task_id" placeholder="Gorevler...">
                        <el-option v-for="item in tasks" :key="item.id" :label="item.name" :value="item.id"/>
                    </el-select>
                </el-form-item>

                <div class="text-right">
                    <div class="ui button labeled icon green" @click="store">
                        <i class="icon el-icon-check"></i> Kaydet
                    </div>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'Tasks',
        props: [],
        computed: mapState([
            'cardShow'
        ]),
        data() {
            return {
                pageLoading: true,
                form: {
                    //
                },

                dialogVisible: false,
                dialogLoading: false,

                tasks: [],
                items: null,
            }
        },

        methods: {
            getTasks() {
				this.pageLoading = true;

				this.$http
						.get(this.serviceLink('tasks'))
						.then(response => {
							this.pageLoading = false;
							this.tasks = response.body.data;
						})
						.catch(e => {
							this.pageLoading = false;
						});
            },

            index() {
                this.pageLoading = true;

                this.$http
                    // .get(this.serviceLink('tasks/cards') + '&page=' + (this.pagination ? this.pagination.current_page : 1) + '&card_id=' + this.cardShow.current_card.id)
                    .get(this.serviceLink('tasks/cards') + '&page=' + (this.pagination ? this.pagination.current_page : 1) + '&crm_user_id=' + this.cardShow.current_card.crm_user_id)
                    .then(response => {
                        this.pageLoading = false;
                        this.items = response.body.data;
                    })
                    .catch(e => {
                        this.pageLoading = false;
                    });
            },

            create() {
                this.form = {};
                this.dialogVisible = true;
            },

            store() {
                this.dialogLoading = true;
                this.form.crm_card_id = this.cardShow.current_card.id;

                this
                    .$http
                    .post(this.serviceLink('tasks/cards'), this.form)
                    .then(response => {
                        this.dialogLoading = false;
                        this.dialogVisible = false;

                        this.index();
                    })
                    .catch((e) => {
                        this.dialogLoading = false;
                    });
            },

			destroy(id) {
				this.pageLoading = true;

				this.$confirm('Bu veriyi silmek istediginizden eminmisiniz?', 'Uyari!', {
					confirmButtonText: 'Sil',
					cancelButtonText: 'Iptal',
					type: 'warning'
				})
						.then(() => {
							this.$http
									.delete(this.serviceLink('tasks/cards/' + id))
									.then(response => {
										this.pageLoading = false;

										this.$message({
											type: 'success',
											message: 'Isleminiz basarili bir sekilde gerceklestirildi.'
										});

										this.index();
									})
									.catch(error => {
										this.pageLoading = false;
									})
						})
						.catch(() => {
							this.pageLoading = false;
						});
			},
        },

        mounted() {
            this.pageLoading = false;

            this.getTasks();
            this.index();
        },
    }
</script>