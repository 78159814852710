<style lang="scss" rel="stylesheet/scss">
	@import "./resources/scss/App";
</style>

<template>
	<el-container>
		<transition name="fade">
			<div class="preloader" v-if="preloader">
				<div class="wrap">
					<div class="loader">
						<img src="@/resources/images/preloader.gif" alt="">
					</div>
					<div class="logo">
						<img src="@/resources/images/preloader-logo.png" alt="">
					</div>
				</div>
			</div>
		</transition>

		<el-container :class="'provider ' + (!isSidebarCollapse ? 'is-collopse ' : ' ') + $route.meta.layout.main">
			<Header @collapse-nav-toggle="isSidebarCollapse = !isSidebarCollapse" v-if="$route.meta.layout.header" />
			<Main :class="$route.meta.layout.main" />
			<Footer v-if="$route.meta.layout.footer" />
		</el-container>
	</el-container>
</template>

<script>
    import Sidebar from "./app/Layouts/Sidebar";
    import Footer from "./app/Layouts/Footer";
    import Main from "./app/Layouts/Main";
    import Header from "./app/Layouts/Header";

    export default {
        name: 'Provider',
        components: {
            Header,
            Main,
            Footer,
            Sidebar
        },
        data() {
            return {
                isSidebarCollapse : true,
            };
        },
        computed: {
            preloader() {
                return this.$store.getters.preloader
            }
        },
    }
</script>
