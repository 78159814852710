<template>
    <div class="page scrumboard">
        <div class="pipelines scrollable">
            <div class="pipeline-dash">
                <div :style="pageContainer">
                    <div class="pipeline-stages">
                        <Inbox v-if="inbox" :key="inbox.key"/>
                        <Potential v-if="potential" :key="potential.key"/>
                        <Order v-if="order" :key="order.key"/>
                        <Calendar v-if="calendar" :createData="createData" :key="calendar.key"/>
                        <!--<Task v-if="tasks" :createData="createData"/>-->
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
                title="Yeni Kart Ekle"
                :visible.sync="visibleCardCreateDialog"
                :before-close="handleCloseCardCreateDialog"
                width="60%"
        >
            <el-form
                    ref="create"
                    :model="create"
                    :label-position="'top'"
                    v-loading="pageLoading"
                    v-if="createData"
            >
                <el-form-item label="Başlık">
                    <el-input v-model="create.name" placeholder="Başlık"/>
                </el-form-item>

                <el-form-item label="Aciklama">
                    <el-input type="textarea" v-model="create.description" placeholder="Aciklama"/>
                </el-form-item>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="İşlem Hattı">
                            <el-select
                                    v-model="create.pipeline_id"
                                    placeholder="Seçiniz..."
                                    @change="handleChangeCardCreateDialog"
                            >
                                <el-option
                                        v-for="item in createData.stages"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                />
                            </el-select>
                        </el-form-item>

                        <el-form-item label="Durum" v-if="create.pipeline">
                            <el-select v-model="create.crm_stage_id" placeholder="Seçiniz...">
                                <el-option
                                        v-for="item in create.pipeline.states"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">


                        <el-row :gutter="20">
                            <el-col :span="24">
                                <!-- <el-form-item label="E-Posta">
                                  <el-input v-model="create.email" placeholder="E-Posta" />
                                </el-form-item>-->
                                <el-form-item label="E-Posta">
                                    <el-autocomplete
                                            v-model="create.email"
                                            class="fluid"
                                            :debounce="1000"
                                            placeholder="E-Posta veya Arama"
                                            :fetch-suggestions="mailSearchAsync"
                                            @select="handleMailSearchAsync"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="Telefon">
                                    <el-autocomplete
                                            v-model="create.phone"
                                            :debounce="1000"
                                            class="fluid"
                                            placeholder="Telefon veya Arama"
                                            :fetch-suggestions="phoneSearchAsync"
                                            @select="handlePhoneSearchAsync"
                                    />
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item label="Adi">
                                    <el-input v-model="create.first_name" placeholder="Adı"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Soyadi">
                                    <el-input v-model="create.last_name" placeholder="Soyadı"/>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>

                <div class="text-right">
                    <div class="ui button labeled icon green" @click="handleSaveCard">
                        <i class="icon el-icon-check"></i> Kaydet
                    </div>
                </div>
            </el-form>
        </el-dialog>

        <el-dialog
                class="pipeline-stage-card-detail"
                title="Kart Bilgileri"
                :visible.sync="visibleCardShowDialog"
                :before-close="handleCloseCardCardShowDialog"
                width="90%"
                v-loading="pageLoading"
        >
            <div class="close" @click="handleCloseCardCardShowDialog">
                <i class="el-icon-close"></i>
            </div>

            <div class="container" v-if="cardShow" :key="cardShow.current_card.id">
                <Info/>
                <div class="wrapper">
                    <el-row :gutter="24">
                        <el-col :span="6">
                            <Customer :createData="createData"/>
                            <Discounts/>
                            <Messages/>
                        </el-col>
                        <el-col :span="12">
                            <div class="content">
                                <Stage :createData="createData"/>
                                <Tags/>
                                <Events :createData="createData"/>
                                <RecommendedProducts :createData="createData"/>
                                <Orders/>
                                <Note/>

                              <div v-html="cardShow.current_card.content"></div>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="sidebar">

                                <Cards/>
                                <TargetExam :createData="createData"/>
                                <ExamHistory :createData="createData"/>
                                <Tasks/>
                                <Logs/>
                                <Stats/>

                                <el-button @click="visibleCardDeleteDialog=true" type="danger red"><i
                                        class="mdi mdi-trash-can-outline"></i> BU KARTI SİL
                                </el-button>
                                <el-button @click="refreshCard({id:cardShow.current_card.id})" type="primary yellow"><i
                                        class="mdi mdi-refresh"></i> KARTI YENİLE
                                </el-button>

                              <div style="margin-top: 20px" v-if="cardShow.current_card.content">
                                <el-button @click="openBlankWindow('https://www.remzihoca.com/messages/lead-report/' + cardShow.current_card.id)" type="primary" class="fluid">
                                  <i class="mdi mdi-chart-areaspline"></i> Rehberlik Hizmeti Rapor
                                </el-button>
                              </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </el-dialog>
        <el-dialog title="Kartı Sil"
                   :visible.sync="visibleCardDeleteDialog"
                   width="30%">
            <el-alert
                    title="Bu kartı silmek istediğinizden emin misiniz?"
                    type="error"
                    :closable="false"
                    description="Eğer eminseniz lütfen kartı silme sebebinizi giriniz."
                    show-icon>
            </el-alert>
            <el-input
                    type="textarea"
                    autosize
                    class="margin-20"
                    placeholder="Lütfen giriniz"
                    v-model="deleteReason">
            </el-input>
            <el-button class="margin-20" @click="handleDeleteCard" type="danger red"><i
                    class="mdi mdi-trash-can-outline"></i> BU KARTI SİL
            </el-button>
        </el-dialog>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import Tags from "./Detail/Tags";
    import Info from "./Detail/Info";
    import Stats from "./Detail/Stats";
    import Note from "./Detail/Note";
    import Discounts from "./Detail/Discounts";
    import Orders from "./Detail/Orders";
    import Returns from "./Detail/Returns";
    import Customer from "./Detail/Customer";
    import RecommendedProducts from "./Detail/RecommendedProducts";
    import Cards from "./Detail/Cards";
    import TargetExam from "./Detail/TargetExam";
    import ExamHistory from "./Detail/ExamHistory";
    import Stage from "./Detail/Stage";
    import Logs from "./Detail/Logs";
    import Inbox from "./Stages/Inbox";
    import Potential from "./Stages/Potential";
    import Order from "./Stages/Order";
    import Calendar from "./Stages/Calendar";
    import Task from "./Stages/Task";
    import Events from "./Detail/Events";
    import Tasks from "./Detail/Tasks";
    import Messages from "./Detail/Messages";


    import permissions from '../../../permissions'

    export default {
        name      : "ScrumBoard",
        components: {
            Messages,
            Tasks,
            Events,
            Task,
            Calendar,
            Order,
            Potential,
            Inbox,
            Logs,
            Stage,
            ExamHistory,
            TargetExam,
            Cards,
            RecommendedProducts,
            Customer,
            Returns,
            Orders,
            Note,
            Discounts,
            Stats,
            Info,
            Tags,
            permissions
        },
        computed  : mapState([
            "inbox",
            "potential",
            "order",
            "calendar",
            "tasks",

            "cardCreateDialog",
            "cardShowDialog",
            "card",
            "cardShow",
            "refreshBoard"
        ]),
        watch     : {
            cardCreateDialog(newValue, oldValue) {
                this.visibleCardCreateDialog = newValue;
            },

            cardShowDialog(newValue, oldValue) {
                this.visibleCardShowDialog = newValue;
            },

            card(newValue, oldValue) {
                console.log("Updating card...");
                if (!newValue) {
                    this.show = null;
                    this.$store.commit("setCardShow", null);

                    return;
                }
                console.log("Getting card...");
                this.pageLoading = true;

                this.refreshCard(newValue);
            },

            refreshBoard(newValue) {
                if (newValue) {
                    console.log(this.order.key);

                    this.initBoard();
                }
            }
        },
        data() {
            return {
                pageContainer:
                    "height:" +
                    (window.innerHeight) +
                    "px; width: " +
                    (window.innerWidth - 20) +
                    "px",
                pageLoading  : true,

                visibleCardCreateDialog: false,
                visibleCardDeleteDialog: false,
                deleteReason           : '',
                createData             : null,
                create                 : {
                    //
                },

                visibleCardShowDialog: false,
                show                 : null
            };
        },
        methods   : {
            refreshCard(newValue) {
                this.pageLoading = true;
                this.$store.commit("resetCardShow");
                this.$http
                    .get("cards/" + newValue.id + "?token=" + localStorage.token)
                    .then(response => {
                        console.log("Card loaded...");
                        this.$store.commit("setCardShow", response.body);
                        this.show = response.body;

                        this.pageLoading = false;
                    })
                    .catch(e => {
                        this.pageLoading = false;
                    });
            },
            initBoard() {
                this.pageLoading = true;

                this.$http
                    .get("index?token=" + localStorage.token)
                    .then(response => {
                        this.$store.commit("setGeneralTarget", response.body.general_target);
                        this.$store.commit(
                            "setPersonalTarget",
                            response.body.personal_target
                        );
                        this.$store.commit("setNotifications", response.body.notifications);

                        let inbox = {
                            key      : Math.random(),
                            pipelines: response.body.inbox
                        };

                        let potential = response.body.potential;
                        let order = response.body.order;
                        let calendar = response.body.calendar;
                        let tasks = response.body.tasks;
                        let agents = response.body.agents;

                        potential.key = Math.random();
                        order.key = Math.random();
                        calendar.key = Math.random();
                        tasks.key = Math.random();

                        this.$store.commit("setInbox", inbox);
                        this.$store.commit("setPotential", potential);
                        this.$store.commit("setOrder", order);
                        this.$store.commit("setCalendar", calendar);
                        this.$store.commit("setTasks", tasks);
                        this.$store.commit("setAgents", agents);

                        this.$store.commit("setRefreshBoard", false);

                        this.pageLoading = false;
                    })
                    .catch(e => {
                        this.pageLoading = false;
                    });

                this.pageLoading = true;

                this.$http
                    .get("cards/create?token=" + localStorage.token)
                    .then(response => {
                        this.createData = response.body;
                        this.$store.commit('setAllProducts', response.body.all_products);
                        this.pageLoading = false;
                    })
                    .catch(e => {
                        this.pageLoading = false;
                    });
            },

            handleCloseCardCreateDialog() {
                this.$store.commit("setCardCreateDialog", false);
                this.create = {};
            },

            handleChangeCardCreateDialog() {
                if (!this.create.pipeline_id) {
                    return;
                }

                this.create.pipeline = this.lodash.filter(this.createData.stages, o => {
                    return o.id === this.create.pipeline_id;
                })[0];
            },

            handleSaveCard() {
                this.pageLoading = true;

                this.$http
                    .post("cards?token=" + localStorage.token, this.create)
                    .then(response => {
                        response.body.item.pipeline_id = this.create.pipeline_id;

                        this.initNewCard(response.body.item);

                        this.handleCloseCardCreateDialog();
                        this.pageLoading = false;
                    })
                    .catch(e => {
                        this.pageLoading = false;
                    });
            },

            handleDeleteCard() {
                this.pageLoading = true;

                if (this.deleteReason.length == 0) {
                    this.$message.error('Silmek için sebebinizi girmelisiniz.');
                } else {

                    var deleteData = {
                        card_id: this.card.id,
                        reason : this.deleteReason
                    }
                    this.$http
                        .delete("cards/" + this.card.id + "?token=" + localStorage.token, {params: deleteData})
                        .then(response => {

                            this.visibleCardDeleteDialog = false;
                            this.pageLoading = false;
                        })
                        .catch(e => {
                            this.pageLoading = false;
                        });
                }
            },

            initNewCard(card) {
                if (card.pipeline_id === 5) {
                    return this.initNewCardPotential(card);
                }

                if (card.pipeline_id === 6) {
                    return this.initNewCardOrder(card);
                }

                let item = this.lodash.clone(this.inbox);
                let pipelines = this.lodash.clone(item.pipelines);

                this.lodash.forEach(item.pipelines, (pipeline, pipelineKey) => {
                    if (pipeline.id === card.pipeline_id) {
                        this.lodash.forEach(pipeline.stages, (stage, stageKey) => {
                            if (stage.id === card.crm_stage_id) {
                                pipelines[pipelineKey].stages[stageKey].data = [card].concat(
                                    stage.data
                                );
                            }
                        });
                    }
                });

                item.key = Math.random();
                item.pipelines = pipelines;

                return this.$store.commit("setInbox", item);
            },

            initNewCardOrder(card) {
                let item = this.lodash.clone(this.order);
                let stages = this.lodash.clone(item.stages);

                item.key = Math.random();

                this.lodash.forEach(stages, (stage, stageKey) => {
                    if (stage.id === card.crm_stage_id) {
                        stages[stageKey].data = [card].concat(stage.data);
                    }
                });

                return this.$store.commit("setOrder", item);
            },

            initNewCardPotential(card) {
                let item = this.lodash.clone(this.potential);
                let stages = this.lodash.clone(item.stages);

                item.key = Math.random();

                this.lodash.forEach(stages, (stage, stageKey) => {
                    if (stage.id === card.crm_stage_id) {
                        stages[stageKey].data = [card].concat(stage.data);
                    }
                });

                return this.$store.commit("setPotential", item);
            },

            handleCloseCardCardShowDialog() {
                this.$store.commit("setCardShowDialog", false);
                this.$store.commit("setCard", null);
            },
            mailSearchAsync(query, cb) {

                this.$http
                    .get("search-user?token=" + localStorage.token + "&type=" + "email" + '&email=' + this.create.email)
                    .then(response => {
                        cb(response.body);
                    })
                    .catch(e => {
                        this.pageLoading = false;
                    });

            },
            handleMailSearchAsync(item) {
                this.create.first_name = item.first_name;
                this.create.last_name = item.last_name;
                this.create.phone = item.phone;
                this.create.email = item.value;
            },

            phoneSearchAsync(query, cb) {

                this.$http
                    .get("search-user?token=" + localStorage.token + "&type=" + "phone" + '&phone=' + this.create.phone)
                    .then(response => {
                        cb(response.body);
                    })
                    .catch(e => {
                        this.pageLoading = false;
                    });

            },
            handlePhoneSearchAsync(item) {
                this.create.first_name = item.first_name;
                this.create.last_name = item.last_name;
                this.create.phone = item.value;
                this.create.email = item.email;
            }
        },

        mounted() {
            console.log(this.$store.state.user);
            this.pageLoading = false;
            this.initBoard();
        }
    };
</script>