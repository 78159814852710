<template>
    <div class="card" @click="show">
        <div class="title cursor-pointer">
            {{ cardShow.current_card.name }}
        </div>
        <div class="description cursor-pointer">
            <span v-if="!cardShow.current_card.description"><i class="mdi mdi-pen"></i> Açıklama Ekle</span>
            <span v-if="cardShow.current_card.description"><i class="mdi mdi-pen"></i> {{ cardShow.current_card.description }}</span>

        </div>

        <el-dialog title="Kart Bilgileri" :visible.sync="dialogVisible" append-to-body>
            <el-form ref="form" :model="form" :label-position="'top'" v-loading="pageLoading">

                <el-form-item label="Başlık">
                    <el-input v-model="cardShow.current_card.name" placeholder="Başlık"/>
                </el-form-item>

                <el-form-item label="Açıklama">
                    <el-input type="textarea" v-model="cardShow.current_card.description" placeholder="Açıklama"/>
                </el-form-item>

                <div class="text-right">
                    <div class="ui button labeled icon green" @click="update">
                        <i class="icon el-icon-check"></i> Kaydet
                    </div>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'Info',
        props: [],
        computed: mapState([
            'cardShow'
        ]),
        data() {
            return {
                pageLoading: true,
                dialogVisible: false,
                form: {
                    //
                }
            }
        },

        methods: {

            show() {
                this.dialogVisible = true;
                this.pageLoading = false;
            },

            update() {
                this.pageLoading = true;

                this
                    .$http
                    .put(this.serviceLink('cards/' + this.cardShow.current_card.id), {
                    	name : this.cardShow.current_card.name,
						description : this.cardShow.current_card.description,
					})
                    .then(response => {
                        this.pageLoading = false;
                        this.dialogVisible = false;

                        this.visibleCardDetail(this.cardShow.current_card);

                        this.index();
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                    });
            },

			visibleCardDetail(card) {
				this.$store.commit('setCardShowDialog', true);
				this.$store.commit('setCard', card);
			}
        },

        mounted() {
            this.pageLoading = false;

        },
    }
</script>