<template>
    <div class="ps-container  view box grow ps" v-loading="pageLoading">
        <el-row :gutter="24">
            <el-col :span="24">
                <el-breadcrumb class="bg-primary-lighter" separator="/">
                    <el-breadcrumb-item :to="{ path: '/' }"><i class="mdi mdi-home"></i> Dashboard</el-breadcrumb-item>
                    <el-breadcrumb-item>Tüm Raporlar</el-breadcrumb-item>
                </el-breadcrumb>
                <el-card class="box-card">
                    <router-link to="/reports/interactions">
                        <el-button class="p-50 m-30" type="success" plain>
                            <div><i style="font-size: 72px" class="mdi mdi-swap-horizontal"></i></div>
                            Mail&SMS
                        </el-button>
                    </router-link>
                    <router-link to="/reports/stage-stats">
                        <el-button class="p-50 m-30" type="danger" plain>
                            <div><i style="font-size: 72px" class="mdi mdi-chart-pie"></i></div>
                            İstatistikler
                        </el-button>
                    </router-link>
                    <router-link to="/reports/products">
                        <el-button class="p-50 m-30" type="primary" plain>
                            <div><i style="font-size: 72px" class="mdi mdi-package-variant"></i></div>
                            Urun Raporlari
                        </el-button>
                    </router-link>
                    <router-link to="/reports/targets">
                        <el-button class="p-50 m-30" type="warning" plain>
                            <div><i style="font-size: 72px" class="mdi mdi-target"></i></div>
                            Hedefler
                        </el-button>
                    </router-link>
                    <router-link to="/reports/combinations">
                        <el-button class="p-50 m-30" type="primary" plain>
                            <div><i style="font-size: 72px" class="mdi mdi-basket-fill"></i></div>
                            Satış Hesaplama
                        </el-button>
                    </router-link>
                    <router-link to="/reports/user">
                        <el-button class="p-50 m-30" type="warning" plain>
                            <div><i style="font-size: 72px" class="mdi mdi-export"></i></div>
                            Kullanıcı Raporları
                        </el-button>
                    </router-link>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: 'Index',
        data() {
            return {
                pageLoading: true,
            }
        },

        methods: {
            accessTarget() {

                if (!this.$store.state.user) {
                    return;
                }

                let access = false;

                this.lodash.forEach(this.$store.state.user.roles, (role, key) => {
                    if (role.slug === 'god-mode') {
                        access = true;
                    }
                });

                return access;
            }
        },

        mounted() {
            this.pageLoading = false;
        },
    }
</script>