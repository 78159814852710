<template>
    <div class="stats section">
        <div class="section-header has-button">
            <div class="title">
                Platform
            </div>
            <div class="actions">
                <div class="ui button icon primary circular">
                    W
                </div>
                <div class="ui button icon primary circular">
                    D
                </div>
                <div class="ui button icon primary circular">
                    <i class="icon el-icon-user"></i>
                </div>
            </div>
        </div>
        <div class="section-content no-padding">
            <div class="section-list">
                <div class="section-list-item">
					<div>
						Wordy Öğrendiği Kelime - <span class="font-weight-600">{{ cardShow.stats.wordy_1 }} Kelime</span>
					</div>
                </div>
				<div class="section-list-item">
					<div>
						Wordy Toplam Kelime - <span class="font-weight-600">{{ cardShow.stats.wordy_2 }} Kelime</span>
					</div>
				</div>
				<div class="section-list-item">
					<div>
						Toplam İzlediği Ders - <span class="font-weight-600">{{ cardShow.stats.wordy_3 }} Ders</span>
					</div>
				</div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'Stats',
        props: [],
        computed: mapState([
            'cardShow'
        ]),
        data() {
            return {
                pageLoading: true,
            }
        },
        mounted() {
            this.pageLoading = false;
        },
    }
</script>