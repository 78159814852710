<template>
    <div class="page auth">
        <div class="background-box">
            <img src="@/resources/images/flat-space-background.jpg" alt="" :style="height">
        </div>
        <div class="auth-container">
            <div class="auth-box">
                <div class="form-container">
                    <div class="logo">
                        <img src="@/resources/images/auth-logo.svg" alt="logo" class="ui image small centered">
                    </div>
                    <el-form ref="form" label-position="top" :model="form">
                        <el-form-item>
                            <el-input v-model="form.email" prefix-icon="el-icon-user" placeholder="E-mail"/>
                        </el-form-item>
                        <el-form-item>
                            <el-input v-model="form.password" prefix-icon="el-icon-key" type="password"
                                      placeholder="Password"/>
                        </el-form-item>
                        <div class="columns">

                            <div class="column">
                                <el-button round type="primary" :class="{loading : loading, disabled : loading}"
                                           @click="send">GİRİŞ
                                </el-button>
                            </div>
                            <div class="column">
                                <a href="">
                                    Şifremi Unuttum
                                </a>
                            </div>
                        </div>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const axios = require('axios');

    export default {
        name: 'Login',
        data() {
            return {
                loading: false,
                height: 'height:' + window.innerHeight + 'px',
                form: {
                    email: null,
                    password: null,
                }
            }
        },
        methods: {
            send() {
                this.loading = true;
                this.domain = 'https://www.remzihoca.com';

                axios.post(this.domain + '/platform/login?token=false', this.form)
                    .then((response) => {

                        this.$cookies.config('30d');
                        this.$cookies.set('platform-token', response.data.token);

                        localStorage.setItem('token', response.data.token);

                        this.loading = false;

                        this.$store.commit('setUser', response.data.user);

                        this.goRoute('/');
                    })
                    .catch((error) => {
                        this.loading = false;
                    });
            }
        },
        mounted() {
            console.log(process.env.NODE_ENV);

        },
    }
</script>