<template>
	<div class="section">
		<div class="section-header">
			<div class="title">
				Sipariş Geçmişi
			</div>
		</div>
		<div class="section-content no-padding">
			<div class="section-list">
				 <div class="section-list-item" v-for="item in cardShow.current_card.bought_products" :key="item.id">
					<div class="meta display-flex">
						<div class="meta-item margin-right-10">{{item.order_date}}</div>
						<div class="meta-item font-weight-600 margin-right-10">#{{item.payment_code}}</div>
						<div class="meta-item"><i v-if="item.is_gift" class="mdi mdi-gift-outline text-green"></i> {{item.name}}</div>
					</div>
					<div class="date text-green font-weight-600">
						{{item.difference}} Gün
					</div>
				</div> 
				<!-- <div class="section-list-item" v-for="item in cardShow.current_card.bought_products" :key="item.id">
					<div class="meta">
						<div class="meta-item">{{item.order_date}}</div>
						<div class="meta-item font-weight-600">#431452</div>
						<div class="meta-item">21.03.2018</div>
					</div>
					<div class="date">
						43 Gun Kaldi
					</div>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';

    export default {
        name: 'Orders',
		props : [],
		computed: mapState([
			'cardShow'
		]),
        data() {
            return {
                pageLoading: true,
            }
        },
        mounted() {
            this.pageLoading = false;
        },
    }

</script>