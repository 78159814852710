<template>
  <div class="ps-container page-page-headers view box grow ps" ref="container" v-loading="pageLoading">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <h1>Önceden Oluşturulanlar Tablosu</h1>
      </div>
      <el-table :data="dataTableData.data" style="width: 100%">
        <el-table-column prop="id" label="ID" width="80"></el-table-column>
        <el-table-column prop="user.full_name" label="Kullanıcı" width="180"></el-table-column>
        <el-table-column prop="description" label="Açıklama" width="400"></el-table-column>
        <el-table-column prop="count" label="Sayı" align="right"></el-table-column>
        <el-table-column prop="created_at" label="Oluşturma Tarihi" width="165" align="right"></el-table-column>
        <el-table-column label="File" align="right" width="165">
          <template slot-scope="scope">
            <div>
              <el-tag v-if="!scope.row.is_approved" type="danger">Onay Bekliyor</el-tag>
              <a v-if="scope.row.is_approved" target="_blank"
                 v-bind:href=" domain +scope.row.file">İndir</a>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="canUser('cms.crm.reports.update')" label="Aksiyon" width="280" align="right">
          <template slot-scope="scope">
            <el-button v-show="scope.row.is_approved === 0" type="success" @click="approveReport(scope.row)"
                       size="small">
              Onayla
            </el-button>
            <el-button v-show="scope.row.is_approved === 0" type="secondary"
                       @click="previewQuery(scope.row)">
              Filtreleri Görüntüle
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: center;padding: 10px;">
        <el-pagination
            background
            layout="prev, pager, next"
            :total="dataTableData.total"
            :current-page.sync="dataTableData.current_page"
            @current-change="handlePaginationChange"
            :page-size="dataTableData.per_page"/>
      </div>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <h1>Kullanıcı Filtreleme ve Csv alma</h1>
      </div>
      <el-form label-position="top" ref="form" :rules="rules" :model="form" label-width="100px">
        <el-card>
          <el-row :gutter="24">
            <el-col :span="5">
              <el-checkbox v-model="form.hasProductBasedFilter">Ürün Bazlı</el-checkbox>
            </el-col>
            <el-col :span="5">

              <el-checkbox v-model="form.hasActiveProductsFilter">Aktif Ürünü Olanlar</el-checkbox>
            </el-col>
            <el-col :span="5">
              <el-checkbox v-model="form.hasMarketingPermissionFilter">Pazarlama İzni Olanlar
              </el-checkbox>
            </el-col>
            <el-col :span="5">
              <el-tooltip
                  content=" (Canlı,Kayıttan,Geçmiş Dönem, Uzatma ) |  Promosyon Ürünler (okuma,vs) gibi ürünlerden muaf et"
                  placement="top">
                <el-checkbox v-model="form.hasOnlyLiveOrRecordFilter">Sadece Canlı veya Kayıttan
                </el-checkbox>
              </el-tooltip>

            </el-col>
            <el-col :span="5">
              <el-checkbox v-model="form.hasLoyaltyDiscountAmount">Eski Katılımcı İndirimi Olan
              </el-checkbox>
            </el-col>
          </el-row>
        </el-card>
        <el-card v-show="form.hasProductBasedFilter">
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="Sahip Olunan Ürünler">
                <el-select v-model="form.productsWith" placeholder="Urunler..." multiple filterable
                           class="basic" value="">
                  <el-option v-for="item in filteredProducts" :key="item.id"
                             :label="item.name" :value="item.id"/>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Sahip Olunmayan Ürünler">
                <el-select v-model="form.productsWithout" placeholder="Urunler..." multiple filterable
                           class="basic" value="">
                  <el-option v-for="item in filteredProducts" :key="item.id"
                             :label="item.name" :value="item.id"/>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="Satin Alma Tarihine Gore">
            <el-row :gutter="24">
              <el-col :span="12">
                <el-date-picker v-model="form.order_start_at" type="date" placeholder="Başlangıç Tarihini Seçiniz"
                                value-format="yyyy-MM-dd HH:mm:ss"/>
              </el-col>
              <el-col :span="12">
                <el-date-picker v-model="form.order_end_at" type="date" placeholder="Bitis Tarihini Seçiniz"
                                value-format="yyyy-MM-dd HH:mm:ss"/>
              </el-col>
            </el-row>
          </el-form-item>
        </el-card>

        <el-card>
          <el-row :gutter="24">
            <el-col :span="12">
              <h3>Yds</h3>
              <el-row :gutter="24">
                <el-col :span="24">
                  <el-form-item label="Daha Önce YDS'ye girdiniz mi?">
                    <el-select v-model="form.mi_have_you_entered_yds_before" clearable>
                      <el-option v-for="item in approves" :key="item.value" :label="item.label" :value="item.value"/>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Aldığı Puan">
                    <el-row :gutter="24">
                      <el-col :span="12">
                        <el-input v-model="form.mi_yds_how_many_points_did_you_get_min" placeholder="min"/>
                      </el-col>
                      <el-col :span="12">
                        <el-input v-model="form.mi_yds_how_many_points_did_you_get_max" placeholder="max"/>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Hedeflediği Puan">
                    <el-row :gutter="24">
                      <el-col :span="12">
                        <el-input v-model="form.mi_yds_how_many_points_are_you_aiming_min" placeholder="min"/>
                      </el-col>
                      <el-col :span="12">
                        <el-input v-model="form.mi_yds_how_many_points_are_you_aiming_max" placeholder="max"/>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="12">
              <h3>Yökdil</h3>
              <el-row :gutter="24">
                <el-col :span="24">
                  <el-form-item label="Daha Önce YDS'ye girdiniz mi?">
                    <el-select v-model="form.mi_have_you_entered_yokdil_before" clearable>
                      <el-option v-for="item in approves" :key="item.value" :label="item.label" :value="item.value"/>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Aldığı Puan">
                    <el-row :gutter="24">
                      <el-col :span="12">
                        <el-input v-model="form.mi_yokdil_how_many_points_did_you_get_min" placeholder="min"/>
                      </el-col>
                      <el-col :span="12">
                        <el-input v-model="form.mi_yokdil_how_many_points_did_you_get_max" placeholder="max"/>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Hedeflediği Puan">
                    <el-row :gutter="24">
                      <el-col :span="12">
                        <el-input v-model="form.mi_yokdil_how_many_points_are_you_aiming_min" placeholder="min"/>
                      </el-col>
                      <el-col :span="12">
                        <el-input v-model="form.mi_yokdil_how_many_points_are_you_aiming_max" placeholder="max"/>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="6">
              <el-form-item label="ALES'e girmeyi düşünüyor musunuz?">
                <el-select v-model="form.mi_are_you_planning_to_enter_ales" clearable>
                  <el-option v-for="item in approves" :key="item.value" :label="item.label" :value="item.value"/>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="Genel İngilizce eğitimleri almak ister misiniz?">
                <el-select v-model="form.mi_would_you_like_to_receive_general_english_training" clearable>
                  <el-option v-for="item in approves" :key="item.value" :label="item.label" :value="item.value"/>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="Konuşma eğitimleri almak ister misiniz?">
                <el-select v-model="form.mi_would_you_like_to_receive_speaking_lessons" clearable>
                  <el-option v-for="item in approves" :key="item.value" :label="item.label" :value="item.value"/>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="Çocuğunuzun İngilizce eğitim almasını ister misiniz?">
                <el-select v-model="form.mi_would_you_like_your_child_to_study_english" clearable>
                  <el-option v-for="item in approves" :key="item.value" :label="item.label" :value="item.value"/>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>

        <el-card>
          <el-form-item label="Musteri Kayit Olma Tarihine Gore">
            <el-row :gutter="24">
              <el-col :span="12">
                <el-date-picker v-model="form.user_start_at" type="date" placeholder="Başlangıç Tarihini Seçiniz"
                                value-format="yyyy-MM-dd HH:mm:ss"/>
              </el-col>
              <el-col :span="12">
                <el-date-picker v-model="form.user_end_at" type="date" placeholder="Bitis Tarihini Seçiniz"
                                value-format="yyyy-MM-dd HH:mm:ss"/>
              </el-col>
            </el-row>
          </el-form-item>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="Kullanıcı Bilgileri" prop="selectedUserFields">
                <el-select v-model="form.selectedUserFields" placeholder="Kullnıcı Bilgileri..."
                           multiple filterable
                           class="basic" value="">
                  <el-option v-for="item in userFields" :key="item.key"
                             :label="item.value" :value="item"/>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>

        <el-card>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="Açıklama (Sebep) - Bu raporu almanızdaki sebebi açıklayınız."
                            prop="filterDescription">
                <el-input type="textarea" v-model="form.filterDescription"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
        <el-form-item>
          <el-button type="primary" @click="calculateButton">Filtrele - Dosya Oluştur
          </el-button>
        </el-form-item>
      </el-form>

    </el-card>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: 'Users',
  data() {
    return {

      form: {
        hasActiveProductsFilter: false,
        hasProductBasedFilter: false,
        hasMarketingPermissionFilter: true,
        hasOnlyLiveOrRecordFilter: true,
        hasLoyaltyDiscountAmount: false,
        filterDescription: "",
        productsWith: [],
        productsWithout: [],
        selectedUserFields: [{key: "email", value: "Email", type: "field"},],

        mi_have_you_entered_yds_before: null,
        mi_yds_how_many_points_did_you_get_min: null,
        mi_yds_how_many_points_did_you_get_max: null,
        mi_yds_how_many_points_are_you_aiming_min: null,
        mi_yds_how_many_points_are_you_aiming_max: null,

        mi_have_you_entered_yokdil_before: null,
        mi_yokdil_how_many_points_did_you_get_min: null,
        mi_yokdil_how_many_points_did_you_get_max: null,
        mi_yokdil_how_many_points_are_you_aiming_min: null,
        mi_yokdil_how_many_points_are_you_aiming_max: null,

        mi_are_you_planning_to_enter_ales: null,
        mi_would_you_like_to_receive_general_english_training: null,
        mi_would_you_like_to_receive_speaking_lessons: null,
        mi_would_you_like_your_child_to_study_english: null,
      },
      rules: {
        filterDescription: [
          {required: true, message: 'Açıklama Alanı Zorunludur', trigger: 'blur'},],
        selectedUserFields: [
          {required: true, message: 'Seçim Yapılmalıdır', trigger: 'change'},]
      },

      resultText: null,
      resultData: [],
      allProducts: [],
      pageLoading: true,
      userFields: [
        {key: "first_name", value: "İsim", type: "field"},
        {key: "last_name", value: "Soyisim", type: "field"},
        {key: "email", value: "Email", type: "field"},
        {key: "phone", value: "Telefon", type: "field"},
        {key: "loyalty_discount_amount", value: "Eski Katılımcı Miktarı", type: "field"},
        {key: "birthday", value: "Doğum Tarihi", type: "field"},
        {key: "user_product_total_price", value: "Alışveriş Tutarı", type: "custom"},
        {key: "created_at", value: "Musteri Kayit Tarihi", type: "field"},
        {key: "payment_confirmed_at", value: "Satin Alma Tarihi", type: "custom"},
      ],
      dataTableData: [],
      domain: "",

      points: [],
      approves: [
        {
          value: 0,
          label: 'Hayır'
        },
        {
          value: 1,
          label: 'Evet'
        },
      ],
    }
  },
  computed: {
    filteredProducts() {
      return this.allProducts;
    },

    ...mapState(['user']),
    ...mapGetters(['canUser'])
  },
  mounted() {
    this.getAllProducts();
    this.index();
    this.domain = 'https://www.remzihoca.com/';
  },
  methods: {
    getAllProducts() {
      this.pageLoading = true;

      this.$http
          .get(this.serviceLink('combination/all-products'))
          .then(response => {
            this.pageLoading = false;
            this.allProducts = response.body;
          })
          .catch(e => {
            this.pageLoading = false;
          });
    },

    index(page = 1) {
      this.pageLoading = true;

      this.$http
          .get('report/user?token=' + localStorage.token + '&page=' + page)
          .then(response => {
            this.pageLoading = false;
            this.dataTableData = response.body;
          })
          .catch(e => {
            this.pageLoading = false;
          });
    },
    approveReport(row) {

      this.pageLoading = true;

      this.$http
          .post('report/user/approve?token=' + localStorage.token, row)
          .then(response => {
            this.pageLoading = false;
            this.index();
          })
          .catch(e => {
            this.pageLoading = false;
          });
    },

    previewQuery(row) {
      let mainDiv = document.getElementById('Main');
      mainDiv.scrollTop = mainDiv.scrollHeight;

      let jsonFormQuery = JSON.parse(row.request);
      delete jsonFormQuery['token'];
      this.form = jsonFormQuery;

      this.$notify({
        type: 'info',
        title: 'Filtreler!',
        message: "Seçili Filtreler Uygulandı Dikkatli Ol! :)"
      });

    },
    calculateButton() {


      this.$refs['form'].validate((valid) => {
        if (valid) {


          this.pageLoading = true;

          this.$http
              .post('report/user?token=' + localStorage.token, this.form)
              .then(response => {
                this.pageLoading = false;
                this.$message({
                  message: 'Dosyanız Oluşmuştur ,Onay sonrası Yukarıdaki tablodan görüntüleyebilirsiniz.',
                  type: 'success'
                });
                this.index();

              })
              .catch(e => {
                this.pageLoading = false;
              });

        } else {
          console.log('error submit!!');
          return false;
        }
      });


    },
    handlePaginationChange(page) {
      this.index(page)
    },
    getPoints() {
      for (let i = 5; i <= 100; i += 5) {
        this.points.push({
          value: i,
          label: i + ' Puan'
        })
      }

      return this.points
    },
  },

}
</script>

<style scoped>

</style>
