<template>
	<div class="section">
		<div class="section-header">
			<div class="title">
				Diğer Kartlar
			</div>
		</div>
		<div class="section-content no-padding">
			<div class="section-list">
				<div class="section-list-item cursor-pointer font-weight-200" v-for="card in cardShow.other_cards " :key="card.id" @click="visibleCardDetail(card)">
					<div class="name">
						<i :class="card.icon ? card.icon : 'mdi mdi-circle'" :style="{color: card.color}"></i>
						{{ card.name }}
					</div>
					<div class="date">{{ card.readable_date_format }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';

    export default {
        name: 'Cards',
		props : [],
		computed: mapState([
			'cardShow'
		]),
        data() {
            return {
                pageLoading: true,
            }
        },

		methods : {
			visibleCardDetail(card) {
				this.$store.commit('setCardShowDialog', true);
				this.$store.commit('setCard', card);
			}
		},

        mounted() {
            this.pageLoading = false;
        },
    }
</script>