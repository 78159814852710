<template>
    <div v-loading="pageLoading">
        <div class="d-flex">
            <el-tooltip content="Kaydet" placement="top">
                <el-button @click="create" type="success" class="p-10" plain icon="el-icon-check"/>
            </el-tooltip>
            <el-tooltip content="Filtreler" placement="top">
                <el-button @click="show" type="warning" class="p-10" plain icon="el-icon-document"/>
            </el-tooltip>
        </div>

        <el-dialog title="Filtre Kaydet" :visible.sync="createDialogVisible">
            <el-form ref="form" :model="form" :label-position="'top'" v-loading="dialogLoading">
                <el-form-item label="Baslik">
                    <el-input v-model="form.title" placeholder="Baslik"/>
                </el-form-item>

                <div class="text-right">
                    <div class="ui button labeled icon green" @click="store">
                        <i class="icon el-icon-check"></i> Kaydet
                    </div>
                </div>
            </el-form>
        </el-dialog>

        <el-dialog title="Filtreler" :visible.sync="showDialogVisible" v-loading="dialogLoading">
            <div class="items">
                <div class="item d-flex" v-for="item in items" :key="item.id" style="margin-bottom: 10px">
                    <div class="title">
                        <b>{{ item.title }}</b>
                    </div>
                    <div class="actions">
                        <div class="ui button icon" @click="initFilterForm(item)">
                            <i class="el-icon-check"></i>
                        </div>
                        <div class="ui button icon red" @click="destroy(item.id)">
                            <i class="el-icon-delete"></i>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: 'FilterRecord',
        props: ['alias', 'filterForm', 'stage'],
        data() {
            return {
                endPoint: 'filter-records/' + this.alias,
                pageLoading: true,
                dialogLoading: false,
                createDialogVisible: false,
                showDialogVisible: false,
                form: {
                    //
                },
                items: [],
            }
        },

        methods: {
            index() {
                this.dialogLoading = true;

                this.$http
                    .get(this.serviceLink(this.endPoint))
                    .then(response => {
                        this.dialogLoading = false;
                        this.items = response.body;
                    })
                    .catch(e => {
                        this.dialogLoading = false;
                    });

            },

            create() {
                if (this.alias === 'crm-stage-reports') {
                    if (!this.lodash.size(this.filterForm.old_stage_id) && !this.lodash.size(this.filterForm.new_stage_id)) {
                        return this.$notify({
                            type: 'warning',
                            title: 'Uyari!',
                            message: 'Uzgunum en az bir islem hatti secmeniz gerekiyor!'
                        });
                    }

                    if (this.lodash.size(this.filterForm.old_stage_id) && this.lodash.size(this.filterForm.old_stage_id) < 2) {
                        return this.$notify({
                            type: 'warning',
                            title: 'Uyari!',
                            message: 'Baslangic Asamasi alt kirilim secmeniz gerekiyor.'
                        });
                    }

                    if (this.lodash.size(this.filterForm.new_stage_id) && this.lodash.size(this.filterForm.new_stage_id) < 2) {
                        return this.$notify({
                            type: 'warning',
                            title: 'Uyari!',
                            message: 'Bitis Asamasi alt kirilim secmeniz gerekiyor.'
                        });
                    }
                }

                if (!this.filterForm.start_at) {
                    return this.$notify({
                        type: 'warning',
                        title: 'Uyari!',
                        message: 'Baslangic tarihini dolurmaniz gerekiyor'
                    });
                }

                if (!this.filterForm.end_at) {
                    return this.$notify({
                        type: 'warning',
                        title: 'Uyari!',
                        message: 'Bitis tarihini dolurmaniz gerekiyor'
                    });
                }

                this.form = {};
                this.createDialogVisible = true;
            },

            store() {
                this.form.alias = this.alias;
                this.form.meta = {};
                this.dialogLoading = true;

                this.lodash.forEach(this.filterForm, (value, key) => {
                    this.form.meta[key] = value;
                });

                this
                    .$http
                    .post(this.serviceLink(this.endPoint), this.form)
                    .then(response => {
                        this.dialogLoading = false;
                        this.createDialogVisible = false;

                        this.index();
                    })
                    .catch((e) => {
                        this.dialogLoading = false;
                    });
            },

            show() {
                this.showDialogVisible = true;

                this.index();
            },

            destroy(id) {
                this.dialogLoading = true;

                this.$confirm('Bu veriyi silmek istediginizden eminmisiniz?', 'Uyari!', {
                    confirmButtonText: 'Sil',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                    .then(() => {
                        this.$http
                            .delete(this.serviceLink(this.endPoint + '/' + id))
                            .then(response => {
                                this.dialogLoading = false;

                                this.$message({
                                    type: 'success',
                                    message: 'Isleminiz basarili bir sekilde gerceklestirildi.'
                                });

                                this.index();
                            })
                            .catch(error => {
                                this.dialogLoading = false;
                            })
                    })
                    .catch(() => {
                        this.dialogLoading = false;
                    });
            },

            initFilterForm(item) {

                let data = {};

                this.lodash.forEach(item.metas, (meta, key) => {
                    if (meta.key === 'old_stage_id' || meta.key === 'new_stage_id') {
                        data[meta.key] = JSON.parse(meta.value);
                    } else {
                        data[meta.key] = meta.value;
                    }
                });

                this.showDialogVisible = false;

				this.stage.__vue__.initForm(data);
            }
        },

        mounted() {
            this.pageLoading = false;


        },
    }
</script>