<style scoped>
    .list-item {

    }

    .list-enter-active,
    .list-leave-active {
        transition: all 1s;
    }

    .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */
    {
        opacity: 0;
        transform: translateY(30px);
    }

    .from-socket-color {
        background-color: #f1f1f1;
    }
</style>

<template>
    <div class="pipeline-stage">
        <div class="pipeline-stage-header">
            <el-select v-model="form.pipeline_id" placeholder="Havuz" @change="handleChangePipeline">
                <el-option
                        v-for="item in inbox.pipelines"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                />
            </el-select>
        </div>
        <div class="pipeline-stage-container" v-loading="pageLoading">

            <div v-if="pipeline" class="stage-tabs">
                <el-tooltip
                        :content="item.name"
                        placement="bottom"
                        v-for="item in pipeline.stages"
                        :key="item.id"
                >
                    <div
                            class="tab"
                            :class="{'active' : item.id === form.stage_id}"
                            @click="handleClickStage(item)"
                    >
                        <span class="bullet" :style="{background: item.color}"></span>
                        {{ item.short_name }}
                    </div>
                </el-tooltip>
            </div>
            <div v-if="pagination && pagination.total > pagination.per_page">
                <el-pagination background
                               layout="prev, pager, next"
                               pager-count="3"
                               :total="pagination.total"
                               :current-page.sync="pagination.current_page"
                               :page-size="pagination.per_page"
                               @current-change="handlePaginationChange"
                />
            </div>
            <div class="pipeline-stage-cards">
                <transition-group name="list" :style="transitionStyle">
                    <div v-for="card in orderedCards" :key="card.id" v-bind:class="card.is_from_socket? 'from-socket-color':''"
                         class="pipeline-stage-card list-item" v-if="stage" @click="visibleCardDetail(card)">
                        <div class="name">
                            <i :class="card.icon ? card.icon : 'mdi mdi-circle'" :style="{color: card.color}"></i>
                            {{ card.name }}
                        </div>
                        <div class="date">{{ card.readable_date_format }}</div>
                    </div>
                </transition-group>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import {setInterval, clearInterval} from 'timers';

    export default {
        name: "Inbox",
        computed: {
            ...mapState([
                'inbox',
            ]),
            orderedCards() {
                // return _.orderBy(this.cards, 'created_at').reverse();
                return this.cards;
            }
        },
        props: [],
        data() {
            return {
                pageLoading: true,
                transitionStyle: "height: "+(window.innerHeight-170)+"px; display: block; overflow: scroll",
                form: {
                    //
                },
                pipeline: null,
                stage: null,
                cards: [],

                pagination: null,
            };
        },
        methods: {
            initStages() {
                this.stage = this.pipeline.stages[0];
                this.form.stage_id = this.stage.id;
                this.pagination = null;

                this.index();
            },

            index() {
                this.pageLoading = true;

                this.$http
                    .get(this.serviceLink('cards') + '&page=' + (this.pagination ? this.pagination.current_page : 1) + '&stage_id=' + this.form.stage_id)
                    .then(response => {
                        this.pageLoading = false;

                        this.pagination = {
                            current_page: response.body.current_page,
                            per_page: response.body.per_page,
                            total: response.body.total,
                        };

                        this.cards = response.body.data;

                        this.$store.commit('initInboxCards', response.body.data);
                    })
                    .catch(e => {
                        this.pageLoading = false;
                    });
            },

            handleChangePipeline() {
                this.pipeline = this.lodash.filter(this.inbox.pipelines, o => {
                    return o.id === this.form.pipeline_id;
                })[0];

                this.initStages();
            },

            handleClickStage(stage) {
                if (!stage) {
                    this.form.stage_id = null;
                } else {
                    this.form.stage_id = stage.id;
                }

                this.pagination = null;

                this.index();

            },

            visibleCardDetail(card) {
                this.$store.commit("setCardShowDialog", true);
                this.$store.commit("setCard", card);
            },
            colorChanger: function () {
                this.$store.commit("clearSocketClassFromAllCards", "atilla");
            },

            handlePaginationChange(page) {
                this.pagination.current_page = page;

                this.index();
            },
        },
        mounted() {
            this.pageLoading = false;
            this.pipeline = this.inbox.pipelines[1];
            this.form.pipeline_id = this.pipeline.id;
            setInterval(this.colorChanger, 50000);
            this.initStages();

            window.Echo.channel("crm").listen(
                ".Remzihoca\\Events\\Crm\\CrmCardChangeEvent",
                e => {
                    console.log()
                    if(e.crm_stage_id == this.form.stage_id) {
                        this.cards.unshift(
                            {
                                id: e.card_id,
                                name: e.card_name,
                                readable_date_format: e.readable_date_format
                            }
                        )
                    }
                }
            );
            window.Echo.channel("crm").listen(
                ".Remzihoca\\Events\\Crm\\CrmCardStateChangeEvent",
                e => {
                    if(e.new_stage_id == this.form.stage_id) {
                        this.cards.unshift(
                            {
                                id: e.card_id,
                                name: e.card_name,
                                readable_date_format: e.readable_date_format
                            }
                        )
                    }
                    if(e.old_stage_id == this.form.stage_id) {
                        let i = this.cards.map(item => item.id).indexOf(e.card_id);
                        this.cards.splice(i, 1);
                    }
                }
            );

        },
        destroyed() {
            //clear interval unutma
            //clearInterval(this.colorChanger);
        }
    };
</script>

