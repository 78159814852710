import Vue from 'vue'

Vue.mixin({
    data() {
        return {
            Auth: {
                permissions: [
                    "crm-card-suggestion",
                    "crm-task-assign",

                ],
                users: [
                    {"zeynep@remzihoca.com": ["crm-card-suggestion", "crm-task-assign"  ]}
                ]
            }
        }
    },
    methods: {
        hasPermission(email, permission) {
            var user = _.filter(this.Auth.users, email);
            if (user.length == 1) {

                var permission = user[0][email].indexOf(permission);

                return permission > -1 ? true : false;
            } else {
                return false;
            }
        }
    }
});