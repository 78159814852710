<template>
	<div class="section">
		<div class="section-header">
			<div class="title">
				Iadeler
			</div>
		</div>
		<div class="section-content no-padding">
			<div class="section-list">
				<div class="section-list-item">
					<div>
						Pika
					</div>
					<div>
						25 TL
					</div>
				</div>
				<div class="section-list-item">
					<div>
						Pika
					</div>
					<div>
						25 TL
					</div>
				</div>
				<div class="section-list-item">
					<div>
						Pika
					</div>
					<div>
						25 TL
					</div>
				</div>
				<div class="section-list-item">
					<div>
						Pika
					</div>
					<div>
						25 TL
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        name: 'Returns',
		props : [],
        data() {
            return {
                pageLoading: true,
            }
        },
        mounted() {
            this.pageLoading = false;
        },
    }
</script>