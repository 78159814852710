<template>
    <div class="pipeline-stage">


        <el-popover placement="bottom" width="380" trigger="click">
            <div class="el-popover__content">
                <header class="header">
                    <el-tooltip
                            class="item"
                            effect="dark"
                            content="Yeni Görev Ekle"
                            placement="left"
                    >
                        <el-button icon="mdi mdi-plus" type="orange" @click="create()" size="mini" circle/>
                    </el-tooltip>
                </header>
                <div class="list-items scrool-height-290" v-loading="pageLoading">
                    <a @click="show(task.id)" class="item" v-for="task in pipeline.data">
                        <span class="media">
                            <i class="mdi mdi-briefcase-outline"></i>
                        </span>
                        <div style="display: flex;flex-direction: column;">
                            <div>
                                <span class="text">
                                    <b>{{task.name}}</b>
                                    &nbsp;
                                </span>
                            </div>
                            <div>
                                <span class="text-color-secondary"><b>Başlangıç:</b> {{task.start_at}} &nbsp;</span>
                            </div>
                            <div>
                                <span class="text-color-secondary"><b>Bitiş:</b> {{task.end_at}} &nbsp;</span>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <el-button slot="reference" type="primary" class="bg-primary" icon="mdi mdi-briefcase-outline">Görevler</el-button>
        </el-popover>


        <el-dialog title="Yeni Gorev Olustur" :visible.sync="dialogVisible" width="60%">
            <el-form ref="form" :model="form" :label-position="'top'" v-loading="dialogLoading">
                <el-form-item label="Başlık">
                    <el-input v-model="form.name" placeholder="Başlık"/>
                </el-form-item>

                <el-form-item label="Aciklama">
                    <el-input v-model="form.description" placeholder="Aciklama" type="textarea"/>
                </el-form-item>

                <el-form-item label="Başlangıç Tarihi">
                    <el-date-picker v-model="form.start_at" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                                    placeholder="Tarih"/>
                </el-form-item>

                <el-form-item label="Bitiş Tarihi">
                    <el-date-picker v-model="form.end_at" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                                    placeholder="Tarih"/>
                </el-form-item>

                <div class="ui button green" @click="store">
                    Kaydet
                </div>
            </el-form>
        </el-dialog>
        <el-dialog title="Gorev Detaylari" :visible.sync="taskDialogVisible" width="60%">

            <el-form ref="task" :model="task" :label-position="'top'" v-loading="taskDialogLoading">
                <h2 class="ui header " @click="showUpdateDialog" style="cursor: pointer">
                    {{ task.name }}

                    <div class="sub header"><b>Başlangıç: </b>{{ task.start_at }}</div>
                    <div class="sub header"><b>Bitiş: </b>{{ task.end_at }}</div>
                </h2>
                <div>
                    {{ task.description }}
                </div>


                <div class="assig-task-area box-card" style="padding-top: 10px" v-show="canUser('cms.crm.assign-task.update')">
                    <el-card class="box-card" style="margin-bottom: 5px !important;">
                        <el-row :gutter="24">
                            <el-col :span="20">
                                <span style="font-weight: bold">Görev Dağıtımı</span>
                            </el-col>
                            <el-col :span="4">
                                <el-button type="success" :loading="taskDialogLoading" @click="assignTasksToAgent" icon="el-icon-check">Dağıt
                                </el-button>
                            </el-col>
                        </el-row>

                        <el-row :gutter="24" style="padding-top: 10px">
                            <el-col :span="24">
                                <el-select v-model="selectedAgents" placeholder="Kişiler" multiple filterable :clearable="true"
                                           class="basic">
                                    <el-option v-for="agent in agents" v-bind:key="agent.id" :key="agent.id" :value="agent.id"
                                               :label="agent.full_name"/>
                                </el-select>
                            </el-col>
                        </el-row>
                    </el-card>
                </div>

                <div class="assig-task-area box-card" >
                    <el-card class="box-card">
                        <el-row :gutter="24">
                            <el-col :span="20">
                                <span style="font-weight: bold">Görev Filtreleme</span>
                            </el-col>
                            <el-col :span="4">
                                <el-button type="success" @click="taskFilter" :loading="taskDialogLoading" icon="el-icon-check">Filtrele</el-button>
                            </el-col>
                        </el-row>

                        <el-row :gutter="24" style="padding-top: 10px">
                            <el-col :span="12">
                                <el-input type="text" v-model="filterText" placeholder="Normal Filtreleme"></el-input>
                            </el-col>
                            <el-col :span="12">
                                <el-select v-model="filterSelectedAgent" placeholder="Kişiye Göre Filtreleme" filterable :clearable="true"
                                           class="basic">
                                    <el-option v-for="agent in agents" :key="agent.id"
                                               :label="agent.full_name" :value="agent.id"/>
                                </el-select>
                            </el-col>
                        </el-row>
                    </el-card>
                </div>


                <Cards :task="task" :q="filterText" :agent_id="filterSelectedAgent" :key="task.id" v-if="task.id"/>
            </el-form>
        </el-dialog>

        <el-dialog title="Gorev Detaylarini Guncelle" :visible.sync="taskUpdateDialogVisible" width="60%">
            <el-form ref="task" :model="updateForm" :label-position="'top'" v-loading="taskDialogLoading">

                <el-form-item label="Başlık">
                    <el-input v-model="updateForm.name" placeholder="Başlık"/>
                </el-form-item>

                <el-form-item label="Aciklama">
                    <el-input v-model="updateForm.description" placeholder="Aciklama" type="textarea"/>
                </el-form-item>

                <el-form-item label="Başlangıç Tarihi">
                    <el-date-picker v-model="updateForm.start_at" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="Başlangıç Tarihi"/>
                </el-form-item>

                <el-form-item label="Bitiş Tarihi">
                    <el-date-picker v-model="updateForm.end_at" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="Bitiş Tarihi"/>
                </el-form-item>
                <div class="ui button green" @click="update">
                    Kaydet
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import {mapState, mapGetters} from 'vuex';
    import Cards from "./Tasks/Cards";

    export default {
        name      : 'Task',
        components: {Cards},
        computed  : {
            ...mapState([
                'tasks',
                'agents',
                'user'
            ]),
            ...mapGetters(['canUser'])
        },
        data() {
            return {
                pageLoading            : true,
                dialogLoading          : false,
                dialogVisible          : false,
                taskUpdateDialogVisible: false,
                selectedAgents         : [],
                filterText             : null,
                filterSelectedAgent    : null,
                form                   : {
                    //
                },
                pipeline               : {data: []},
                pagination             : null,

                taskDialogVisible: false,
                taskDialogLoading: false,
                task             : {
                    //
                },

                updateForm: {
                    //
                }
            }
        },
        methods   : {

            assignTasksToAgent() {

                if (this.selectedAgents.length < 1) {

                    this.$message({
                        showClose: true,
                        message  : 'Oops, Boş Alanlar mevcut',
                        type     : 'error'
                    });

                    return null;
                }

                this.taskDialogLoading = true;

                let postData = {
                    "task_id": this.task.id,
                    "agents" : this.selectedAgents,
                }


                this.$http
                    .post(this.serviceLink('tasks/assign'), postData)
                    .then(response => {
                        this.taskDialogLoading = false;

                        this.show(this.task.id);
                    })
                    .catch(e => {
                        this.taskDialogLoading = false;
                    });


            },
            taskFilter(searchText = "", searchAgent = 0) {
                this.show(this.task.id);
            },


            index() {
                this.pageLoading = true;

                this.$http
                    .get(this.serviceLink('tasks') + '&page=' + (this.pagination ? this.pagination.current_page : 1))
                    .then(response => {
                        this.pageLoading = false;

                        this.pagination = {
                            current_page: response.body.current_page,
                            per_page    : response.body.per_page,
                            total       : response.body.total,
                        };

                        this.pipeline = response.body;
                    })
                    .catch(e => {
                        this.pageLoading = false;
                    });
            },

            create() {
                this.form = {};
                this.dialogVisible = true;
            },

            store() {
                this.dialogLoading = true;

                this
                    .$http
                    .post(this.serviceLink('tasks'), this.form)
                    .then(response => {
                        this.dialogLoading = false;
                        this.dialogVisible = false;

                        this.pagination.current_page = 1;

                        this.index();
                    })
                    .catch((e) => {
                        this.dialogLoading = false;
                    });
            },

            show(id) {
                this.task = {};
                this.taskDialogLoading = true;
                this.taskDialogVisible = true;

                this.$http
                    .get(this.serviceLink('tasks/' + id))
                    .then(response => {
                        this.taskDialogLoading = false;
                        this.task = response.body;
                    })
                    .catch(e => {
                        this.taskDialogLoading = false;
                    });
            },

            showUpdateDialog() {
                this.updateForm = this.task;
                this.taskUpdateDialogVisible = true;
            },

            update() {
                this.taskDialogLoading = true;

                this
                    .$http
                    .put(this.serviceLink('tasks/' + this.updateForm.id), this.updateForm)
                    .then(response => {
                        this.taskDialogLoading = false;
                        this.taskUpdateDialogVisible = false;

                        this.updateForm = {};

                        this.show(this.task.id);
                    })
                    .catch((e) => {
                        this.taskDialogLoading = false;
                    });
            },

            handlePaginationChange(page) {
                this.pagination.current_page = page;

                this.index();
            },
        },
        mounted() {
            this.pageLoading = false;

            this.index();
        },
    }
</script>